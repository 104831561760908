import { DataTable, TableFooter } from 'bold-ui'
import { booleanToSimNaoEnum, simNaoEnumRecord } from 'components/form'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { css } from 'emotion'
import React from 'react'
import { formatNumber } from 'util/number'

import { ParticipanteModel } from '../../model-atividadeColetiva'
import { sortByNomeAndIdadeParticipante } from '../../util-atividadeColetiva'
import { ParticipanteCidadaoInfo } from './ParticipanteCidadaoInfo'
import { ParticipanteImc } from './ParticipanteImc'

interface ParticipantesTableProps {
  items: ParticipanteModel[]
  dataAtividade: LocalDate
  isPraticaSaudePnct: boolean
}

export function ParticipantesTable(props: ParticipantesTableProps) {
  const { items, dataAtividade, isPraticaSaudePnct } = props
  const { paginatedItems, tableProps } = usePagination({ items: items.slice().sort(sortByNomeAndIdadeParticipante) })

  return (
    <TableBox>
      <DataTable<ParticipanteModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => <ParticipanteCidadaoInfo participante={row} />,
            style: styles.tableRowNome,
          },
          {
            name: 'avaliacaoAlterada',
            header: 'Avaliação alterada',
            render: (row) => (row.avaliacaoAlterada ? 'Sim' : 'Não'),
          },
          {
            name: 'peso',
            header: 'Peso (Kg)',
            render: (row) => (row.peso ? formatNumber(row.peso) : '-'),
          },
          {
            name: 'altura',
            header: 'Altura (cm)',
            render: (row) => (row.altura ? formatNumber(row.altura, 0) : '-'),
          },
          {
            name: 'imc',
            header: 'IMC',
            render: (row) => <ParticipanteImc participante={row} dataAtividade={dataAtividade} />,
            style: styles.tableRowIMC,
          },
          isPraticaSaudePnct && {
            name: 'pnctCessouFumar',
            header: 'Cessou o hábito de fumar?',
            render: (row) => simNaoEnumRecord[booleanToSimNaoEnum(row.pnctCessouHabitoFumar)] ?? 'Não informado',
            style: styles.tableRowPnct,
          },
          isPraticaSaudePnct && {
            name: 'pnctAbandonouGrupo',
            header: 'Abandonou o grupo?',
            render: (row) => simNaoEnumRecord[booleanToSimNaoEnum(row.pnctAbandonouGrupo)] ?? 'Não informado',
            style: styles.tableRowPnct,
          },
        ].filterNotFalsy()}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
  tableRowNome: css`
    width: 15rem;
  `,
  tableRowIMC: css`
    width: 20rem;
  `,
  tableRowPnct: css`
    width: 9rem;
  `,
}
