import { Cell, Grid, Heading, HeadingSection, HFlow, Icon, InfoLabel, Text } from 'bold-ui'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Cep, NumeroDeclaracaoObito, Telefone } from 'components/label'
import React from 'react'
import { identidadeGenero, orientacaoSexual, tipoSanguineo } from 'types/enums'

import { CidadaoInformacoesViewProps } from './CidadaoInformacoesView'

const CY_CIDADAO = 'CidadaoInformacoesGerais'
const CY_CIDADAO_ALDEADO = 'CidadaoAldeadoInformacoesGerais'

export const GrupoObito = ({ cidadao, isCidadaoAldeado }: CidadaoInformacoesViewProps) => {
  const dataCy = isCidadaoAldeado ? CY_CIDADAO_ALDEADO : CY_CIDADAO

  return (
    <Box>
      <Grid>
        <Cell style={{ width: '12rem' }}>
          <Heading color='normal' level={3}>
            Cidadão em óbito
          </Heading>
        </Cell>
        <Cell size={2} data-cy={`${dataCy}.dataObito`}>
          <InfoLabel title='Data de óbito'>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataObito} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy={`${dataCy}.numeroDeclaracaoObito`}>
          <InfoLabel placeholder='-' title='Número da Declaração de Óbito'>
            <NumeroDeclaracaoObito value={cidadao?.numeroDocumentoObito} />
          </InfoLabel>
        </Cell>
      </Grid>
    </Box>
  )
}

export const EquipeResponsavelSection = ({
  cidadao: { cidadaoVinculacaoEquipe },
  isCidadaoAldeado,
}: CidadaoInformacoesViewProps) => {
  const dataCy = isCidadaoAldeado ? CY_CIDADAO_ALDEADO : CY_CIDADAO

  return (
    <HeadingSection level={2} title='Equipe responsável pelo cidadão'>
      <Grid wrap>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.equipeResponsavel`}>
          <InfoLabel placeholder='-' title='Equipe responsável'>
            {cidadaoVinculacaoEquipe?.equipe &&
              `${cidadaoVinculacaoEquipe?.equipe.nome} - ${cidadaoVinculacaoEquipe?.equipe.ine}`.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 6 : 8} data-cy={`${dataCy}.unidadeResponsavel`}>
          <InfoLabel placeholder='-' title='Unidade responsável'>
            {cidadaoVinculacaoEquipe?.unidadeSaude?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        {cidadaoVinculacaoEquipe?.utilizarCadastroIndividual && (
          <Cell size={isCidadaoAldeado ? 6 : 8} data-cy={`${dataCy}.utilizarCadastroIndividual`}>
            <Text>Utilizando a informação do cadastro individual do cidadão</Text>
          </Cell>
        )}
      </Grid>
    </HeadingSection>
  )
}

export const EnderecoSection = ({
  cidadao: { endereco, ...cidadao },
  isCidadaoAldeado,
}: CidadaoInformacoesViewProps) => {
  const dataCy = isCidadaoAldeado ? CY_CIDADAO_ALDEADO : CY_CIDADAO

  return (
    <HeadingSection level={2} title='Endereço'>
      <Grid wrap>
        {isCidadaoAldeado && (
          <Cell size={3} data-cy='CidadaoAldeadoInformacoesGerais.localidade'>
            <InfoLabel placeholder='-' title='Localidade'>
              {cidadao?.tipoEndereco?.titleCase()}
            </InfoLabel>
          </Cell>
        )}
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.paisResidencia`}>
          <InfoLabel placeholder='-' title='País de residência'>
            Brasil
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 6 : 8} data-cy={`${dataCy}.cep`}>
          <InfoLabel placeholder='-' title='CEP'>
            <Cep value={endereco?.cep} />
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.estado`}>
          <InfoLabel placeholder='-' title='Estado'>
            {endereco?.uf?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.municipio`}>
          <InfoLabel placeholder='-' title='Município'>
            {endereco?.municipio?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 6 : 4} data-cy={`${dataCy}.bairro`}>
          <InfoLabel placeholder='-' title='Bairro'>
            {endereco?.bairro?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.tipoLogradouro`}>
          <InfoLabel placeholder='-' title='Tipo de logradouro'>
            {endereco?.tipoLogradouro?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.logradouro`}>
          <InfoLabel placeholder='-' title='Logradouro'>
            {endereco?.logradouro?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 6 : 4} data-cy={`${dataCy}.numero`}>
          <InfoLabel placeholder='-' title='Número'>
            {endereco?.numero?.toUpperCase()}
          </InfoLabel>
        </Cell>

        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.complemento`}>
          <InfoLabel placeholder='-' title='Complemento'>
            {endereco?.complemento?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 9 : 8} data-cy={`${dataCy}.pontoReferencia`}>
          <InfoLabel placeholder='-' title='Ponto de referência'>
            {endereco?.pontoReferencia?.capitalize()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.area`}>
          <InfoLabel placeholder='-' title='Área'>
            {cidadao?.area}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 8} data-cy={`${dataCy}.microArea`}>
          <InfoLabel placeholder='-' title='Microárea'>
            {cidadao?.microArea}
          </InfoLabel>
        </Cell>
        {isCidadaoAldeado && (
          <Cell size={6} data-cy='CidadaoAldeadoInformacoesGerais.numeroFamilia'>
            <InfoLabel placeholder='-' title='Número da família'>
              {cidadao?.numeroFamilia}
            </InfoLabel>
          </Cell>
        )}
      </Grid>
    </HeadingSection>
  )
}

export const EnderecoExteriorSection = ({ cidadao, isCidadaoAldeado }: CidadaoInformacoesViewProps) => {
  const dataCy = isCidadaoAldeado ? CY_CIDADAO_ALDEADO : CY_CIDADAO

  return (
    <HeadingSection level={2} title='Endereço'>
      <Grid wrap>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.paisResidenciaExterior`}>
          <InfoLabel placeholder='-' title='País de residência'>
            {cidadao?.paisExterior.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.municipioResidenciaExterior`}>
          <InfoLabel placeholder='-' title='Município de residência'>
            {cidadao?.localidadeExterior?.titleCase()}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

export const ContatosSection = ({ cidadao, isCidadaoAldeado }: CidadaoInformacoesViewProps) => {
  const dataCy = isCidadaoAldeado ? CY_CIDADAO_ALDEADO : CY_CIDADAO

  return (
    <HeadingSection level={2} title='Contatos'>
      <Grid wrap>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.telefoneResidencial`}>
          <InfoLabel placeholder='-' title='Telefone residencial'>
            <Telefone value={cidadao?.telefoneResidencial} />
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.telefoneCelular`}>
          <InfoLabel placeholder='-' title='Telefone celular'>
            <Telefone value={cidadao?.telefoneCelular} />
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.telefoneContato`}>
          <InfoLabel placeholder='-' title='Telefone de contato'>
            <Telefone value={cidadao?.telefoneContato} />
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 6 : 8} data-cy={`${dataCy}.email`}>
          <InfoLabel placeholder='-' title='Email'>
            {cidadao?.email}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

export const InformacoesSociodemograficasSection = ({ cidadao, isCidadaoAldeado }: CidadaoInformacoesViewProps) => {
  const dataCy = isCidadaoAldeado ? CY_CIDADAO_ALDEADO : CY_CIDADAO

  return (
    <HeadingSection level={2} title='Informações sociodemográficas'>
      <Grid wrap>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.nPIS`}>
          <InfoLabel placeholder='-' title='Nº NIS (PIS/PASEP)'>
            {cidadao?.nisPisPasep}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.estadoCivil`}>
          <InfoLabel placeholder='-' title='Estado civil'>
            {cidadao?.estadoCivil?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 6 : 4} data-cy={`${dataCy}.tipoSanguineo`}>
          <InfoLabel placeholder='-' title='Tipo sanguíneo'>
            {tipoSanguineo[cidadao?.tipoSanguineo]}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.ocupacao`}>
          <InfoLabel placeholder='-' title='Ocupação'>
            {cidadao?.cbo?.nome?.titleCase()}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 9 : 8} data-cy={`${dataCy}.escolaridade`}>
          <InfoLabel placeholder='-' title='Escolaridade'>
            {cidadao?.escolaridade?.nome}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 3 : 4} data-cy={`${dataCy}.orientacaoSexual`}>
          <InfoLabel placeholder='-' title='Orientação sexual'>
            {orientacaoSexual[cidadao?.orientacaoSexualDbEnum]}
          </InfoLabel>
        </Cell>
        <Cell size={isCidadaoAldeado ? 9 : 4} data-cy={`${dataCy}.identidadeGenero`}>
          <InfoLabel placeholder='-' title='Identidade de gênero'>
            {identidadeGenero[cidadao?.identidadeGeneroDbEnum]}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

export const CompartilhamentoProntuarioSection = ({ cidadao, isCidadaoAldeado }: CidadaoInformacoesViewProps) => {
  const dataCy = isCidadaoAldeado ? CY_CIDADAO_ALDEADO : CY_CIDADAO

  return (
    <HeadingSection style={{ marginBottom: '5rem' }} level={2} title='Compartilhamento de prontuário'>
      <Grid wrap>
        <Cell size={4}>
          <Text>
            Por padrão, o prontuário do cidadão é visível por todas as unidades de saúde de uma mesma instalação do
            e-SUS APS, para agilizar o atendimento em toda a rede.
          </Text>
        </Cell>
        <Cell size={8}></Cell>
        <Cell size={4}>
          <HFlow alignItems='center' hSpacing={0.5} data-cy={`${dataCy}.compartilhamentoProntuario`}>
            {cidadao?.stCompartilhaProntuario ? (
              <>
                <Icon icon='checkCircleOutline' size={1} fill='success' />
                <Text color='success'>Compartilhamento de prontuário ativo</Text>
              </>
            ) : (
              <>
                <Icon icon='banOutline' size={1} fill='danger' />
                <Text color='danger'>Compartilhamento de prontuário inativo</Text>
              </>
            )}
          </HFlow>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}
