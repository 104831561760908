import { HFlow, Text, VFlow } from 'bold-ui'
import React from 'react'
import {
  CuidadoCompartilhadoEvolucaoRecordModel,
  LabelSubtitleGarantiaAcessoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

interface HistoricoCuidadoCompartilhadoGarantiaAcessoProps
  extends Pick<CuidadoCompartilhadoEvolucaoRecordModel, 'descricao' | 'enviarGarantiaAcesso'> {}

export function HistoricoCuidadoCompartilhadoGarantiaAcesso(props: HistoricoCuidadoCompartilhadoGarantiaAcessoProps) {
  const { enviarGarantiaAcesso, descricao } = props

  return (
    <VFlow vSpacing={0.25}>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>Demanda: </Text>
        <Text>{LabelSubtitleGarantiaAcessoRecord[enviarGarantiaAcesso]}</Text>
      </HFlow>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>Motivo: </Text>
        <Text dangerouslySetInnerHTML={{ __html: descricao }} />
      </HFlow>
    </VFlow>
  )
}
