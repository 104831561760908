/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, DataTable, Heading, HFlow, InfoLabel, Text, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useFlags } from 'config/useFlagsContext'
import { useHistoricoAtendimentoOdontologicoDwQuery } from 'graphql/hooks.generated'
import { FatoAtendimentoOdonto, FatoAtendOdontoProced } from 'graphql/types.generated'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import {
  HistoricoDwPanelProps,
  historicoSubsectionStyles,
} from 'view/atendimentos/detail/historico/model/model-historico'
import {
  convertFatoMedicoesToHistoricoMedicaoModel,
  convertFatoToReceitaMedicamento,
  hasMedicoes,
  mountInfosAdministrativasAtendimentoCompartilhado,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoAvaliacaoProblemasCondicoesDwTableContent from '../../../../table/dw/HistoricoAvaliacaoProblemasDwTableContent'
import HistoricoPlanoPecPrescricaoPanel from '../../atendimento-individual/consulta/soap/plano/HistoricoPlanoPecPrescricaoPanel'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import { HistoricoIvcfTableBox } from '../../HistoricoIvcfTableBox'
import HistoricoMedicoesTableBox from '../../HistoricoMedicoesTableBox'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'

interface HistoricoAtendimentoOdontoDwProps extends HistoricoDwPanelProps {}

const colunasTabelaProcedimentos = [
  {
    name: 'quantidade',
    header: 'Quantidade',
    render: (item) => <Text fontWeight='bold'>{item.quantidadeProcedimentos}</Text>,
  },
  {
    name: 'procedimento',
    header: 'Procedimento',
    render: (item) =>
      `${item.dimensaoProcedimento.descricaoProcedimento} - ${item.dimensaoProcedimento.codigoProcedimento}`,
    style: css`
      width: 90%;
    `,
  },
]

function HistoricoAtendimentoOdontoDw({
  uuidRegistro,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
}: HistoricoAtendimentoOdontoDwProps) {
  const { IVCF_ENABLED } = useFlags()

  const {
    data: { historicoAtendimentoOdontoDw: historico },
    loading,
  } = useHistoricoAtendimentoOdontologicoDwQuery({
    variables: {
      input: {
        uuidRegistro,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
      isIvcfEnabled: IVCF_ENABLED,
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const medicoes = convertFatoMedicoesToHistoricoMedicaoModel(historico as FatoAtendimentoOdonto)

  const hasAnyMedicao = hasMedicoes(medicoes)
  const hasPlano =
    !isEmpty(historico.procedimentos) || !isEmpty(historico.fornecimento) || !isEmpty(historico.medicamentos)
  const hasProblemas = !isEmpty(historico.problemas)
  const hasAvaliacao = hasProblemas || !isEmpty(historico.vigilanciaSaudeBucal)
  const hasDesfecho = !isEmpty(historico.conduta) || !isEmpty(historico.encaminhamento)
  const hasIvcf = !isEmpty(historico.ivcf)

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentoCompartilhado(historico)}
      tipoParticipacaoCidadao={historico.dimTipoParticipacaoCidadao?.descricao}
    >
      <VFlow>
        {(historico.isPacienteNecessidadesEspeciais || historico.isGestante) && (
          <HFlow>
            {historico.isPacienteNecessidadesEspeciais && (
              <Alert type='info' inline>
                <Text color='primary' fontWeight='bold'>
                  Possui necessidades especiais
                </Text>
              </Alert>
            )}
            {historico.isGestante && (
              <Alert type='info' inline>
                <Text color='primary' fontWeight='bold'>
                  Está gestante
                </Text>
              </Alert>
            )}
          </HFlow>
        )}
        <HistoricoSection section='S'>
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        </HistoricoSection>
        <HistoricoSection section='O'>
          <VFlow>
            {!hasAnyMedicao && !hasIvcf && <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>}
            {hasAnyMedicao && <HistoricoMedicoesTableBox sectionStyle='O' medicoes={medicoes} />}
            {hasIvcf && (
              <HistoricoIvcfTableBox
                ivcf={historico.ivcf}
                riscoVulnerabilidadeText={historico.ivcf.dimensaoGrauVulnerabilidadeIvcf.descricao}
              />
            )}
          </VFlow>
        </HistoricoSection>
        <HistoricoSection section='A'>
          {hasAvaliacao ? (
            <VFlow vSpacing={0.5}>
              {hasProblemas && (
                <HistoricoAvaliacaoProblemasCondicoesDwTableContent problemasCondicoes={historico.problemas} />
              )}
              {!isEmpty(historico.vigilanciaSaudeBucal) && (
                <InfoLabel title='Vigilância em saúde bucal' titleStyles={historicoSubsectionStyles.avaliacao}>
                  <Text>{stringGrouper(...historico.vigilanciaSaudeBucal)}</Text>
                </InfoLabel>
              )}
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
        <HistoricoSection section='P'>
          {hasPlano ? (
            <VFlow>
              {!isEmpty(historico.procedimentos) && (
                <VFlow vSpacing={0.5}>
                  <Heading level={5} style={historicoSubsectionStyles.plano}>
                    Procedimentos
                  </Heading>
                  <DataTable<FatoAtendOdontoProced>
                    rows={historico.procedimentos}
                    columns={colunasTabelaProcedimentos}
                  />
                </VFlow>
              )}
              {!isEmpty(historico.medicamentos) && (
                <VFlow vSpacing={0.5}>
                  <Heading style={historicoSubsectionStyles.plano} level={5}>
                    Medicamentos prescritos
                  </Heading>
                  <HistoricoPlanoPecPrescricaoPanel
                    prescricaoMedicamentos={historico.medicamentos.map((medicamento) =>
                      convertFatoToReceitaMedicamento(medicamento)
                    )}
                  />
                </VFlow>
              )}
              {!isEmpty(historico.fornecimento) && (
                <InfoLabel title='Fornecimento' titleStyles={historicoSubsectionStyles.plano}>
                  <Text>{stringGrouper(...historico.fornecimento)}</Text>
                </InfoLabel>
              )}
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
        <HistoricoSection section='D'>
          {hasDesfecho ? (
            <VFlow>
              {!isEmpty(historico.conduta) && (
                <InfoLabel title='Conduta' titleStyles={historicoSubsectionStyles.desfecho}>
                  <Text>{stringGrouper(...historico.conduta)}</Text>
                </InfoLabel>
              )}
              {!isEmpty(historico.encaminhamento) && (
                <InfoLabel title='Encaminhamento' titleStyles={historicoSubsectionStyles.desfecho}>
                  <Text>{stringGrouper(...historico.encaminhamento)}</Text>
                </InfoLabel>
              )}
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
      </VFlow>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAtendimentoOdontoDw)
