import { createValidator, ErrorObject, required, requiredRichText, richTextMaxLength } from 'util/validation'
import { ProfissionalCpfCnsIdModel } from 'view/atividade-coletiva/model-atividadeColetiva'
import { getCidadaoProfExistsMsg, hasSameCpfCns } from 'view/atividade-coletiva/saude/utils-atividadeColetivaSaude'

import { ObservacaoCidadaoEditableTableModel } from './ObservacaoCidadaoEditableTable'

export const observacaoCidadaoEditableTableValidator = (
  allItems: ObservacaoCidadaoEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) =>
  createValidator<ObservacaoCidadaoEditableTableModel>(
    {
      cidadao: [required],
      observacao: [requiredRichText, richTextMaxLength(4000)],
    },
    (model: ObservacaoCidadaoEditableTableModel, errors: ErrorObject<ObservacaoCidadaoEditableTableModel>) => {
      const hasSameCidadao = (observacao: ObservacaoCidadaoEditableTableModel) =>
        observacao?._id !== model?._id && hasSameCpfCns(observacao?.cidadao, model?.cidadao)

      if (allItems?.some(hasSameCidadao)) {
        errors.cidadao = 'Cidadão selecionado já possui observações informadas.'
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpfCns(profissional, model?.cidadao))) {
        errors.cidadao = getCidadaoProfExistsMsg('envolvido')
      } else if (hasSameCpfCns(profissionalResponsavel, model?.cidadao)) {
        errors.cidadao = getCidadaoProfExistsMsg('responsável')
      }

      return errors
    }
  )
