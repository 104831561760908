import {
  getEmptyReferenceArea,
  getExpectedReferenceArea,
  getNotExpectedReferenceArea,
  getUnderExpectedReferenceArea,
} from '../converter-graficoGanhoPeso'
import { CategoriaImcColors } from '../model-graficoGanhoPeso'

const BAIXOPESO_P10: ReadonlyArray<number> = [
  -1.52,
  -1.24,
  -0.96,
  -0.68,
  -0.38,
  -0.08,
  0.24,
  0.58,
  0.93,
  1.3,
  1.68,
  2.07,
  2.46,
  2.86,
  3.24,
  3.61,
  3.98,
  4.33,
  4.67,
  5.01,
  5.33,
  5.64,
  5.94,
  6.22,
  6.49,
  6.74,
  6.98,
  7.2,
  7.43,
  7.64,
  7.85,
]

const BAIXOPESO_P18: ReadonlyArray<number> = [
  -0.73,
  -0.43,
  -0.14,
  0.17,
  0.48,
  0.81,
  1.15,
  1.51,
  1.89,
  2.28,
  2.69,
  3.11,
  3.54,
  3.96,
  4.38,
  4.79,
  5.19,
  5.58,
  5.96,
  6.34,
  6.7,
  7.06,
  7.4,
  7.73,
  8.05,
  8.35,
  8.64,
  8.92,
  9.19,
  9.47,
  9.74,
]

const BAIXOPESO_P34: ReadonlyArray<number> = [
  0.26,
  0.58,
  0.91,
  1.24,
  1.58,
  1.94,
  2.31,
  2.71,
  3.12,
  3.56,
  4.01,
  4.47,
  4.94,
  5.41,
  5.87,
  6.33,
  6.78,
  7.22,
  7.66,
  8.08,
  8.5,
  8.91,
  9.31,
  9.69,
  10.07,
  10.43,
  10.78,
  11.13,
  11.47,
  11.82,
  12.16,
]

const BAIXOPESO_P50: ReadonlyArray<number> = [
  1.09,
  1.43,
  1.78,
  2.14,
  2.5,
  2.89,
  3.29,
  3.71,
  4.16,
  4.63,
  5.11,
  5.61,
  6.11,
  6.62,
  7.13,
  7.62,
  8.11,
  8.59,
  9.06,
  9.53,
  9.99,
  10.44,
  10.88,
  11.31,
  11.72,
  12.13,
  12.52,
  12.91,
  13.3,
  13.69,
  14.09,
]

const BAIXOPESO_P90: ReadonlyArray<number> = [
  5.44,
  5.87,
  6.3,
  6.75,
  7.2,
  7.68,
  8.16,
  8.68,
  9.21,
  9.77,
  10.35,
  10.94,
  11.54,
  12.14,
  12.73,
  13.32,
  13.89,
  14.45,
  15.01,
  15.56,
  16.1,
  16.63,
  17.15,
  17.66,
  18.15,
  18.63,
  19.1,
  19.56,
  20.03,
  20.49,
  20.96,
]

export const BAIXO_PESO_COLORS: CategoriaImcColors = {
  strokeAndTick: '#554596',
  expectedSurface: '#BDB5DA',
  unexpectedSurface: '#E8E6F4',
}

const REFERENCE_AREA_P90 = getNotExpectedReferenceArea(BAIXOPESO_P90, 'P90', BAIXO_PESO_COLORS)
const REFERENCE_AREA_P50 = getNotExpectedReferenceArea(BAIXOPESO_P50, 'P50', BAIXO_PESO_COLORS)

const REFERENCE_AREA_P34 = getExpectedReferenceArea(BAIXOPESO_P34, 'P34', 'P18', BAIXO_PESO_COLORS)
const REFERENCE_AREA_ABAIXO_P18 = getUnderExpectedReferenceArea(BAIXOPESO_P18, BAIXO_PESO_COLORS)

const REFERENCE_AREA_P10 = getNotExpectedReferenceArea(BAIXOPESO_P10, 'P10', BAIXO_PESO_COLORS)
const REFERENCE_AREA_ABAIXO_P10 = getEmptyReferenceArea(BAIXOPESO_P10)

export const REFERENCE_AREAS_BAIXO_PESO = [
  REFERENCE_AREA_ABAIXO_P10,
  REFERENCE_AREA_P10,
  REFERENCE_AREA_ABAIXO_P18,
  REFERENCE_AREA_P34,
  REFERENCE_AREA_P50,
  REFERENCE_AREA_P90,
]
