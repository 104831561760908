/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, Heading, InfoLabel } from 'bold-ui'
import { HistoricoObjetivoPecFragmentFragment } from 'graphql/types.generated'
import { isEmpty } from 'lodash'

import HistoricoDetailTable from '../../table/HistoricoDetailTable'

type Ivcf = HistoricoObjetivoPecFragmentFragment['ivcf']

interface HistoricoIvcfTableBoxProps {
  ivcf?: Omit<Ivcf, '__typename' | 'id' | 'riscoVulnerabilidade'>
  riscoVulnerabilidadeText: string
}

export function HistoricoIvcfTableBox({ ivcf, riscoVulnerabilidadeText }: HistoricoIvcfTableBoxProps) {
  return (
    <HistoricoDetailTable title='Índice de Vulnerabilidade Clínico-Funcional (IVCF-20)' sectionStyle='P'>
      <Grid gapVertical={0.5}>
        <Cell size={12}>
          <Heading level={4}>
            {riscoVulnerabilidadeText} | {'ponto'.pluralizeAndConcatValue(ivcf.resultado)}
          </Heading>
        </Cell>
        <Cell size={12}>
          <InfoLabel title='Dimensões alteradas:'>
            {isEmpty(ivcf.dimensoesAlteradas) ? 'Nenhuma alteração' : ivcf.dimensoesAlteradas}.
          </InfoLabel>
        </Cell>
      </Grid>
    </HistoricoDetailTable>
  )
}
