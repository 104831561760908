import { Button, HFlow, Icon, TableFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { Ellipsis } from 'components/Ellipsis'
import { Cns, Cpf } from 'components/label'
import { confirm } from 'components/modals/confirm'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import theme from 'config/theme'
import { css } from 'emotion'
import { FormApi } from 'final-form'
import React, { MouseEvent, useState } from 'react'
import { MetaArray } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { EditableListForm, EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'
import { ObservacaoCidadaoModel, ProfissionalCpfCnsIdModel } from 'view/atividade-coletiva/model-atividadeColetiva'
import { getNomeCidadao } from 'view/atividade-coletiva/util-atividadeColetiva'

import { ObservacaoCidadaoEditableTableModalForm } from './ObservacaoCidadaoEditableTableModalForm'
import { observacaoCidadaoEditableTableValidator } from './validator-observacaoCidadaoEditableTable'

export type ObservacaoCidadaoEditableTableModel = ObservacaoCidadaoModel & {
  _id: ID
}

type ObservacaoCidadaoTableProps = {
  name: MetaArray<ObservacaoCidadaoEditableTableModel>
  profissionalResponsavel: ProfissionalCpfCnsIdModel
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
}

const stopPropagation = (fn: () => any) => (event: MouseEvent<HTMLButtonElement>) => {
  event.stopPropagation()
  fn()
}

export function ObservacaoCidadaoEditableTable(props: ObservacaoCidadaoTableProps) {
  const { name, profissionalResponsavel, profissionaisEnvolvidos } = props
  const [isInserting, setIsInserting] = useState(false)
  const [rowInEdit, setRowInEdit] = useState(null)

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField({ name })

  const allItems: ObservacaoCidadaoEditableTableModel[] = (value || []).slice().reverse()
  const { paginatedItems, tableProps } = usePagination({ items: allItems, initialPageSize: 5 })

  const onClose = () => {
    setIsInserting(false)
    setRowInEdit(null)
  }

  const handleCancel = (formProps: EditableListFormRenderProps<ObservacaoCidadaoEditableTableModel>) => {
    if (formProps.dirty) {
      confirm({
        title: `Deseja cancelar o registro?`,
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: `Não, continuar`,
        confirmLabel: `Sim, cancelar`,
        onConfirm: () => {
          onClose()
          setTimeout(() => formProps.form.reset())
        },
      })()
    } else {
      onClose()
    }
  }

  const onInsert = (values: ObservacaoCidadaoEditableTableModel, formApi: FormApi) => {
    handleSubmit(values, formApi)
    onClose()
  }

  const onEdit = (values: ObservacaoCidadaoEditableTableModel) => {
    handleRowChanged(values)
    onClose()
  }

  const onRemove = (row: ObservacaoCidadaoEditableTableModel) =>
    confirm({
      title: 'Deseja excluir observações sobre cidadão?',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => removeItem(row),
    })()

  const renderForm = (formProps: EditableListFormRenderProps<ObservacaoCidadaoEditableTableModel>) => {
    const { name, handleSubmit } = formProps

    return (
      <>
        <HFlow justifyContent='flex-end' style={styles.tableHeader}>
          <Button onClick={() => setIsInserting(true)} type='button' size='small' kind='primary'>
            <Icon icon='plus' style={styles.icon} />
            Inserir observações sobre cidadão
          </Button>
        </HFlow>

        <ObservacaoCidadaoEditableTableModalForm
          name={name}
          title={rowInEdit ? 'Editar observações sobre cidadão' : 'Observações sobre cidadão'}
          isOpen={!!rowInEdit || isInserting}
          onClose={() => handleCancel(formProps)}
          onSubmit={handleSubmit}
        />
      </>
    )
  }

  const renderInfoCidadao = (row: ObservacaoCidadaoEditableTableModel) => {
    const cidadao = row.cidadao

    return (
      <VFlow vSpacing={0.1}>
        <Text fontWeight='bold'>{getNomeCidadao(cidadao).titleCase()}</Text>
        {(cidadao.cpf && <Cpf value={cidadao.cpf} />) || (cidadao.cns && <Cns value={cidadao.cns} />)}
      </VFlow>
    )
  }

  const renderButtons = (row: ObservacaoCidadaoEditableTableModel) => (
    <HFlow hSpacing={0.5} justifyContent='flex-end'>
      <Tooltip text='Editar'>
        <Button
          type='button'
          skin='ghost'
          size='small'
          onClick={stopPropagation(() => setRowInEdit(row))}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>
      <Tooltip text='Excluir'>
        <Button
          type='button'
          skin='ghost'
          size='small'
          onClick={stopPropagation(() => onRemove(row))}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Icon icon='trashOutline' />
        </Button>
      </Tooltip>
    </HFlow>
  )

  return (
    <TableBox
      header={
        <EditableListForm<ObservacaoCidadaoEditableTableModel>
          render={renderForm}
          onSubmit={rowInEdit ? onEdit : onInsert}
          validate={observacaoCidadaoEditableTableValidator(allItems, profissionalResponsavel, profissionaisEnvolvidos)}
          initialValues={rowInEdit}
          suppressNotificationError
          prefix='new'
        />
      }
    >
      <AccordionDataTable<ObservacaoCidadaoEditableTableModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: renderInfoCidadao,
            size: 2,
          },
          {
            name: 'observacao',
            header: 'Observações sobre cidadão',
            render: (row) => (
              <Ellipsis maxLines={2}>
                <Text dangerouslySetInnerHTML={{ __html: row.observacao }} />
              </Ellipsis>
            ),
            size: 8,
          },
          {
            name: 'buttons',
            render: renderButtons,
            size: 2,
          },
        ]}
        components={{
          AccordionPanel: ({ row }) => (
            <VFlow style={styles.accordion} vSpacing={0.5}>
              <Text fontWeight='bold'>Observações sobre cidadão</Text>
              <Text style={styles.rowObservacao} dangerouslySetInnerHTML={{ __html: row.observacao }} />
            </VFlow>
          ),
        }}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableHeader: css`
    padding-block: 0.5rem;
  `,
  icon: css`
    margin-right: 0.5rem;
  `,
  accordion: css`
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
  `,
  rowObservacao: css`
    word-break: break-word;
  `,
  tableFooter: css`
    border-top: 0;
  `,
}
