import { Text, VFlow } from 'bold-ui'
import { Cns, Cpf } from 'components/label'
import { css } from 'emotion'
import React from 'react'
import { humanizeAge } from 'util/date/humanize-age'
import { ParticipanteModel } from 'view/atividade-coletiva/model-atividadeColetiva'
import { getNomeCidadao } from 'view/atividade-coletiva/util-atividadeColetiva'

interface ParticipanteCidadaoInfoProps {
  participante: ParticipanteModel
}

export const ParticipanteCidadaoInfo = (props: ParticipanteCidadaoInfoProps) => {
  const { participante } = props
  const cidadao = participante.cidadao ?? participante.cidadaoParticipante

  return (
    <VFlow vSpacing={0.1} style={styles.container}>
      <Text fontWeight='bold'>{getNomeCidadao(cidadao)?.titleCase()}</Text>
      {(cidadao?.cpf && <Cpf value={cidadao.cpf} />) || (cidadao?.cns && <Cns value={cidadao.cns} />)}
      <Text>
        {humanizeAge(cidadao.dataNascimento)} | {cidadao.sexo.capitalize()}
      </Text>
    </VFlow>
  )
}

const styles = {
  container: css`
    margin: 1rem 0;
  `,
}
