import { simNaoEnumToBoolean } from 'components/form'
import { booleanToSimNaoEnum } from 'components/form'
import { convertLotacoesToLotacaoEditableTableModel } from 'components/form/editable-table/converter-lotacaoEditableTable'
import { AtividadeColetivaInput } from 'graphql/types.generated'
import { parseNumber } from 'util/number'
import { formatNumber } from 'util/number'
import { ParticipanteModel } from 'view/atividade-coletiva/model-atividadeColetiva'

import { ParticipanteEditableTableModel } from '../componentes/participantes/ParticipanteEditableTable'
import { AtividadeColetivaConvertFormModel, AtividadeColetivaFormModel } from '../model-atividadeColetiva'
import { sortByNomeAndIdadeParticipante } from '../util-atividadeColetiva'

const convertParticipanteToParticipanteEditableTableModel = (
  model: ParticipanteModel,
  index: number
): ParticipanteEditableTableModel => ({
  _id: index,
  isCidadaoParticipanteFormOpen: !!model.cidadaoParticipante,
  cidadao: model.cidadao,
  cidadaoParticipante: model.cidadaoParticipante
    ? {
        nome: model.cidadaoParticipante.nome,
        cpf: model.cidadaoParticipante.cpf,
        cns: model.cidadaoParticipante.cns,
        dataNascimento: model.cidadaoParticipante.dataNascimento,
        sexo: model.cidadaoParticipante.sexo,
      }
    : undefined,
  peso: formatNumber(model.peso),
  altura: formatNumber(model.altura, 0),
  avaliacaoAlterada: model.avaliacaoAlterada,
  pnctCessouHabitoFumar: booleanToSimNaoEnum(model.pnctCessouHabitoFumar),
  pnctAbandonouGrupo: booleanToSimNaoEnum(model.pnctAbandonouGrupo),
})

export const convertParticipantesToParticipantesEditableTableModel = (
  participantes?: ParticipanteModel[]
): ParticipanteEditableTableModel[] =>
  participantes
    ?.slice()
    .sort(sortByNomeAndIdadeParticipante)
    .reverse()
    .map(convertParticipanteToParticipanteEditableTableModel)

export const convertAtividadeColetivaSaudeFormModelToInput = (
  model: AtividadeColetivaFormModel,
  isPraticaSaudePnct: boolean
): AtividadeColetivaInput => ({
  id: model.id ?? undefined,
  tipoAtividadeEnum: model.tipoAtividade,
  lotacaoResponsavelId: model.lotacaoResponsavel?.lotacao.id,
  dataAtividade: model.dataAtividade,
  turnoEnum: model.turno,
  numeroParticipantes: model.numeroParticipantes,
  unidadeSaudeLocalAtividadeId: model.localAtividade?.unidadeSaudeLocalAtividade?.id,
  outraLocalidade: model.localAtividade?.outraLocalidade,
  inepLocalAtividadeId: model.localAtividade?.inep?.id,
  atividadeEducacaoPse: model.atividadePse?.educacao ?? false,
  atividadeSaudePse: model.atividadePse?.saude ?? false,
  lotacaoEnvolvidaIds: model.lotacoesEnvolvidas?.map((item) => item.lotacao.id),
  anotacoes: model.anotacoes,
  procedimentoId: model.procedimento?.id,
  publicoAlvoEnums: model.publicoAlvo ?? undefined,
  praticaSaudeEnums: model.praticasSaude ?? undefined,
  temaSaudeEnums: model.temasSaude ?? undefined,
  participantes: model.participantes?.map((item) => ({
    cidadaoId: item.cidadao?.id,
    cidadaoParticipanteInput: item.cidadaoParticipante,
    peso: parseNumber(item.peso),
    altura: parseNumber(item.altura),
    avaliacaoAlterada: item.avaliacaoAlterada,
    pnctAbandonouGrupo: isPraticaSaudePnct ? simNaoEnumToBoolean[item.pnctAbandonouGrupo] : undefined,
    pnctCessouHabitoFumar: isPraticaSaudePnct ? simNaoEnumToBoolean[item.pnctCessouHabitoFumar] : undefined,
  })),
})

export const convertAtividadeColetivaSaudeToFormModel = (
  atividadeColetiva: AtividadeColetivaConvertFormModel
): AtividadeColetivaFormModel => ({
  id: atividadeColetiva.id,
  tipoAtividade: atividadeColetiva.tipoAtividade,
  lotacaoResponsavel: { lotacao: atividadeColetiva.lotacaoResponsavel },
  dataAtividade: atividadeColetiva.dataAtividade,
  turno: atividadeColetiva.turno,
  numeroParticipantes: atividadeColetiva.numeroParticipantes,
  localAtividade: {
    inep: atividadeColetiva.inepLocalAtividade,
    unidadeSaudeLocalAtividade: atividadeColetiva.unidadeSaudeLocalAtividade,
    outraLocalidade: atividadeColetiva.outraLocalidade,
  },
  atividadePse: {
    educacao: atividadeColetiva.atividadeEducacaoPse,
    saude: atividadeColetiva.atividadeSaudePse,
  },
  lotacoesEnvolvidas: convertLotacoesToLotacaoEditableTableModel(atividadeColetiva.lotacoesEnvolvidas),
  temasSaude: atividadeColetiva.temasSaude,
  publicoAlvo: atividadeColetiva.publicoAlvo,
  praticasSaude: atividadeColetiva.praticasSaude,
  procedimento: atividadeColetiva.procedimento,
  anotacoes: atividadeColetiva.anotacoes,
  participantes: convertParticipantesToParticipantesEditableTableModel(atividadeColetiva.participantes),
})
