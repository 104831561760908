import { CidadaoSelectFieldModel } from 'components/form'
import { cns, cpf, createValidator, empty, ErrorObject, maxLength, nome, range, required } from 'util/validation'
import { CidadaoParticipanteModel, ProfissionalCpfCnsIdModel } from 'view/atividade-coletiva/model-atividadeColetiva'

import {
  getCidadaoProfCpfCnsExistsMsg,
  getCidadaoProfExistsMsg,
  hasSameCns,
  hasSameCpf,
  hasSameCpfCns,
} from '../../saude/utils-atividadeColetivaSaude'
import { ParticipanteEditableTableModel } from './ParticipanteEditableTable'

const cidadaoParticipanteValidator = (
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) =>
  createValidator<CidadaoParticipanteModel>(
    {
      nome: [nome(), maxLength(70)],
      dataNascimento: required,
      sexo: required,
      cns: cns,
      cpf: cpf,
    },
    (model: CidadaoParticipanteModel, errors: ErrorObject<CidadaoParticipanteModel>) => {
      if (
        allItems.some((participante) => hasSameCpf(participante.cidadao ?? participante.cidadaoParticipante, model))
      ) {
        errors.cpf = 'CPF informado já foi cadastrado'
      } else if (hasSameCpf(profissionalResponsavel, model)) {
        errors.cpf = getCidadaoProfCpfCnsExistsMsg('CPF', 'responsável')
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpf(profissional, model))) {
        errors.cpf = getCidadaoProfCpfCnsExistsMsg('CPF', 'envolvido')
      }

      if (
        allItems.some((participante) => hasSameCns(participante.cidadao ?? participante.cidadaoParticipante, model))
      ) {
        errors.cns = 'CNS informado já foi cadastrado'
      } else if (hasSameCns(profissionalResponsavel, model)) {
        errors.cns = getCidadaoProfCpfCnsExistsMsg('CNS', 'responsável')
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCns(profissional, model))) {
        errors.cns = getCidadaoProfCpfCnsExistsMsg('CNS', 'envolvido')
      }

      return errors
    }
  )

export const validateCidadao = (
  model: CidadaoSelectFieldModel,
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) => {
  if (
    allItems?.some((participante) => hasSameCpfCns(participante?.cidadao ?? participante?.cidadaoParticipante, model))
  ) {
    return 'Cidadão informado já foi cadastrado'
  } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpfCns(profissional, model))) {
    return getCidadaoProfExistsMsg('envolvido')
  } else if (hasSameCpfCns(profissionalResponsavel, model)) {
    return getCidadaoProfExistsMsg('responsável')
  }

  return undefined
}

export const participanteEditableTableValidator = (
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) => {
  const validateCidadaoParticipante = cidadaoParticipanteValidator(
    allItems,
    profissionalResponsavel,
    profissionaisEnvolvidos
  )

  return createValidator<ParticipanteEditableTableModel>(
    {
      altura: range(20, 250),
      peso: range(0.5, 500),
    },
    (model: ParticipanteEditableTableModel, errors: ErrorObject<ParticipanteEditableTableModel>) => {
      if (model?.isCidadaoParticipanteFormOpen) {
        errors.cidadaoParticipante = validateCidadaoParticipante(model?.cidadaoParticipante)
        errors.cidadao = empty(model?.cidadao)
      } else {
        errors.cidadaoParticipante = empty(model?.cidadaoParticipante)
        errors.cidadao =
          required(model?.cidadao) ||
          validateCidadao(model?.cidadao, allItems, profissionalResponsavel, profissionaisEnvolvidos)
      }

      return errors
    }
  )
}
