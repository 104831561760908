/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, Text, VFlow } from 'bold-ui'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { getFieldError } from 'components/form/final-form/util'
import { parseISO } from 'date-fns'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { isEmpty } from 'lodash'
import { MutableRefObject, useRef, useState } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { IvcfBox } from './components/IvcfBox'
import { IvcfResultadoAccordion } from './components/IvcfResultadoAccordion'
import { converIvcfFormToView } from './converter-ivcf'
import { IvcfAplicacaoForm } from './ivcf-aplicacao/ivcf-aplicacao-form/IvcfAplicacaoForm'
import { IvcfResultadoView } from './ivcf-resultado/IvcfResultadoView'
import { IvcfAplicacaoModel, IvcfFieldModel } from './model-ivcf'

interface IvcfFieldProps {
  name: MetaPath<IvcfFieldModel>
  cachePath: MetaPath<IvcfAplicacaoModel>
}

export function IvcfField(props: IvcfFieldProps) {
  const { name, cachePath } = props

  const rootRef = useRef<HTMLDivElement>(null)
  const [submitFailed, setSubmitFailed] = useState(false)

  const { meta } = usePecField({ name: name })

  const {
    cidadao: { dataNascimento },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const {
    input: { value: cacheValue, onChange: updateCache },
    tools: { resetToUndefined: resetCache },
  } = usePecField({ name: cachePath, subscription: { value: true } })

  const {
    input: { value, onChange },
  } = usePecField({ name, subscription: { value: true } })

  const isIvcfPreenchido = !isEmpty(value)
  const errorField = getFieldError(meta)

  const handleEditSubmit = (values: IvcfFieldModel, collapseAccordion: () => void) => {
    onChange({ ...values, isEditing: false })
    collapseAccordion()
    resetCache()
  }

  const handleEditCancel = (accordionRef: MutableRefObject<HTMLDivElement>, collapseAccordion: () => void) => {
    onChange({ ...value, isEditing: false })
    collapseAccordion()
    resetCache()
    setTimeout(() => {
      window.scrollTo({ behavior: 'smooth', top: accordionRef.current?.offsetTop - 500 })
    })
  }

  return (
    <div ref={rootRef}>
      <IvcfBox isIvcfPreenchido={isIvcfPreenchido}>
        {!isIvcfPreenchido ? (
          <VFlow>
            <VFlow vSpacing={0.5}>
              <Text>
                As perguntas são direcionadas à pessoa idosa e devem ser confirmadas pelo familiar ou acompanhante,
                desde que convivam com a pessoa idosa e estejam em condições de responder aos questionamentos. Nas
                pessoas idosas incapazes de responder, utilizar as respostas do cuidador.
              </Text>
              <Alert type='info' inline>
                Em geral o tempo de aplicação deste instrumento varia de 5 a 10 minutos.
              </Alert>
            </VFlow>
            <IvcfAplicacaoForm
              name={name}
              cidadaoDataNascimento={dateAsYyyyMmDd(parseISO(dataNascimento))}
              dataInicioAtendimento={dateAsYyyyMmDd(iniciadoEm)}
              rootRef={rootRef}
              onSubmit={onChange}
              updateCache={updateCache}
              initialValues={cacheValue}
            />
          </VFlow>
        ) : (
          <VFlow>
            <IvcfResultadoView values={converIvcfFormToView(value)} />
            {value.isEditing && (errorField || submitFailed) && (
              <VFlow vSpacing={0.5}>
                {errorField && (
                  <Alert type='danger' inline>
                    {errorField}
                  </Alert>
                )}
                {submitFailed && (
                  <Alert type='danger' inline>
                    É necessário responder todas as perguntas do formulário para realizar o cálculo.
                  </Alert>
                )}
              </VFlow>
            )}
            <IvcfResultadoAccordion isRegistradoAgora>
              {(ref, collapse) => (
                <VFlow vSpacing={2}>
                  <IvcfAplicacaoForm
                    name={name}
                    rootRef={ref}
                    cidadaoDataNascimento={dateAsYyyyMmDd(parseISO(dataNascimento))}
                    dataInicioAtendimento={dateAsYyyyMmDd(iniciadoEm)}
                    initialValues={isEmpty(cacheValue) ? value.aplicacao : cacheValue}
                    readOnly={!value.isEditing}
                    isEditing={value.isEditing}
                    hideHeader
                    hideBorder
                    onSubmit={(values) => handleEditSubmit(values, collapse)}
                    onCancelEdit={() => handleEditCancel(ref, collapse)}
                    updateCache={updateCache}
                    setSubmitFailed={setSubmitFailed}
                  />
                </VFlow>
              )}
            </IvcfResultadoAccordion>
          </VFlow>
        )}
      </IvcfBox>
    </div>
  )
}
