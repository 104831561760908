import { PraticaSaudeEnum, TemaSaudeEnum, TipoAtividadeEnum } from 'graphql/types.generated'

import {
  AtividadeColetivaFormModel,
  AtividadePseModel,
  PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO,
  PRATICAS_SAUDE_PNCT,
} from '../model-atividadeColetiva'

export const isOnlyEducacaoPse = (model: { atividadePse?: AtividadePseModel }): boolean =>
  model.atividadePse?.educacao && !model.atividadePse?.saude

export const isPraticaSaudeRequired = (model: AtividadeColetivaFormModel) =>
  model.tipoAtividade === TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO

export const isPraticaSaudeDisabled = (model: AtividadeColetivaFormModel) =>
  model.tipoAtividade === TipoAtividadeEnum.EDUCACAO_EM_SAUDE ||
  model.tipoAtividade === TipoAtividadeEnum.MOBILIZACAO_SOCIAL

export const isProcedimentoDisabled = (model: AtividadeColetivaFormModel) =>
  !model.praticasSaude?.includes(PraticaSaudeEnum.OUTRO_PROCEDIMENTO_COLETIVO)

export const getCidadaoProfExistsMsg = (envolvidoResp: string) =>
  `Cidadão deve ser diferente do profissional ${envolvidoResp}.`

export const getCidadaoProfCpfCnsExistsMsg = (cpfCns: string, envolvidoResp: string) =>
  `${cpfCns} informado deve ser diferente do CPF do profissional ${envolvidoResp}.`

export const isCidadaosParticipantesRequired = (tipoAtividade: TipoAtividadeEnum) =>
  tipoAtividade === TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO ||
  tipoAtividade === TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO

export const isPraticaSaudePnct = (praticasSaude: PraticaSaudeEnum[]) =>
  praticasSaude?.some((item) => PRATICAS_SAUDE_PNCT.includes(item))

export const isAtividadePseRequired = (model: AtividadeColetivaFormModel): boolean =>
  model.temasSaude?.includes(TemaSaudeEnum.SEMANA_SAUDE_NA_ESCOLA)

export const isPse = (model: { atividadePse?: AtividadePseModel }) =>
  model.atividadePse?.educacao || model.atividadePse?.saude

export const isTemasSaudeRequired = (model: AtividadeColetivaFormModel) =>
  model.tipoAtividade && model.tipoAtividade !== TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO

export const isNotInPraticasSaudeAtendimentoEmGrupo = (pratica: PraticaSaudeEnum) =>
  !PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO.includes(pratica)

type HasCpf = { cpf?: string }
type HasCns = { cns?: string }

export const hasSameCpf = (item: HasCpf, model: HasCpf) => model?.cpf && model.cpf === item?.cpf
export const hasSameCns = (item: HasCns, model: HasCns) => model?.cns && model.cns === item?.cns
export const hasSameCpfCns = (item: HasCns & HasCpf, model: HasCns & HasCpf) =>
  hasSameCpf(item, model) || hasSameCns(item, model)
