import { Button, Cell, Grid, HFlow, Icon, Modal, ModalBody, Text } from 'bold-ui'
import { Box } from 'components/Box'
import { RadioGroupField, SimNaoEnum, SimNaoRadioGroupField, SubmitButton } from 'components/form'
import { AnimaisDomicilioSelectField } from 'components/form/field/select/AnimaisDomicilioSelectField/AnimaisDomicilioSelectField'
import {
  TipoAbastecimentoAguaSelectField,
  TipoDomicilioSelectField,
  TipoEscoamentoSanitarioSelectField,
  TipoImovelSelectField,
  TipoMaterialParedeSelectField,
  TipoPosseTerraSelectField,
  TipoSituacaoMoradiaSelectField,
  TipoTratamentoAguaSelectField,
} from 'components/form/field/select/CondicoesMoradiaSelectFields'
import { TipoAcessoDomicilioSelectField } from 'components/form/field/select/CondicoesMoradiaSelectFields/TipoAcessoDomicilioSelectField'
import { TipoDestinoLixoSelectField } from 'components/form/field/select/CondicoesMoradiaSelectFields/TipoDestinoLixoSelectField'
import { TipoEnergiaEletricaSelectField } from 'components/form/field/select/CondicoesMoradiaSelectFields/TipoEnergiaEletricaSelectField'
import { PeriodoUltimaAtualizacaoSelectField } from 'components/form/field/select/PeriodoUltimaAtualizacaoSelectField'
import { PeriodoUltimaVisitaSelectField } from 'components/form/field/select/PeriodoUltimaVisitaSelectField'
import { StatusCadastroSelectField } from 'components/form/field/select/StatusCadastroSelectField'
import { TipoEnderecoEnum, TipoLocalizacaoEnum } from 'graphql/types.generated'
import React from 'react'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'

import { meta } from './MicroareasFilter'

interface MicroareasFilterModalProps {
  hasAnimaisDomicilio: SimNaoEnum
  open: boolean
  tipoLocalizacao: TipoLocalizacaoEnum
  onModalClose(): void
  onSubmit(e: React.SyntheticEvent<HTMLFormElement, Event>): void
  onLimparCampos: () => void
  tipoEndereco: TipoEnderecoEnum
  hasEnergiaEletrica: SimNaoEnum
}

export function MicroareasFilterModal(props: MicroareasFilterModalProps) {
  const {
    hasAnimaisDomicilio,
    hasEnergiaEletrica,
    open,
    tipoLocalizacao,
    onModalClose,
    onSubmit: handleSubmit,
    onLimparCampos: handleLimparCampos,
    tipoEndereco,
  } = props

  return (
    <Modal open={open} onClose={onModalClose} closeOnBackdropClick={false}>
      <ModalBody>
        <Grid>
          <Cell size={6}>
            <StatusCadastroSelectField label='Situação do cadastro' name={meta.isCadastroCompleto} />
          </Cell>
          <Cell size={6}>
            <RadioGroupField
              clearable
              label='Localização'
              name={meta.condicoesMoradia.tipoLocalizacao}
              options={[
                { value: TipoLocalizacaoEnum.URBANA, label: 'Urbana' },
                { value: TipoLocalizacaoEnum.RURAL, label: 'Rural' },
                { value: TipoLocalizacaoEnum.PERIURBANA, label: 'Periurbana' },
              ]}
            />
          </Cell>
          <Cell size={6}>
            <PeriodoUltimaAtualizacaoSelectField label='Atualizado há' name={meta.isCadastroAtualizadoMenosDeUmAno} />
          </Cell>
          <Cell size={6}>
            <TipoImovelSelectField label='Tipo de imóvel' name={meta.tipoImovel} />
          </Cell>
          {!tipoEnderecoIndigena.includes(tipoEndereco) && (
            <>
              <Cell size={6}>
                <PeriodoUltimaVisitaSelectField
                  label='Última visita há'
                  name={meta.isImovelVisitadoMenosDeUmMes}
                  openOnFocus={false}
                />
              </Cell>
              <Cell size={6} />
            </>
          )}
          <Cell size={12} />

          <Cell size={12}>
            <Text fontSize={1} fontWeight='bold'>
              Filtrar por característica do imóvel
            </Text>
          </Cell>
          <Cell size={12}>
            <Box>
              <Grid>
                <Cell size={6}>
                  <TipoDomicilioSelectField label='Tipo de domicílio' name={meta.condicoesMoradia.tipoDomicilio} />
                </Cell>
                <Cell size={6}>
                  <TipoMaterialParedeSelectField
                    label='Material predominante'
                    name={meta.condicoesMoradia.tipoMaterialParede}
                  />
                </Cell>
                <Cell size={6}>
                  <TipoSituacaoMoradiaSelectField
                    label='Situação de moradia/posse de terra'
                    name={meta.condicoesMoradia.tipoSituacaoMoradia}
                  />
                </Cell>
                <Cell size={6}>
                  <TipoAbastecimentoAguaSelectField
                    label='Abastecimento de água'
                    name={meta.condicoesMoradia.tipoAbastecimentoAgua}
                  />
                </Cell>
                <Cell size={6}>
                  <TipoPosseTerraSelectField
                    label='Condições de posse e uso de terra'
                    name={meta.condicoesMoradia.tipoPosseTerra}
                    disabled={tipoLocalizacao !== TipoLocalizacaoEnum.RURAL}
                  />
                </Cell>
                <Cell size={6}>
                  <TipoTratamentoAguaSelectField
                    label='Água para consumo'
                    name={meta.condicoesMoradia.tipoTratamentoAgua}
                  />
                </Cell>
                <Cell size={6}>
                  <SimNaoRadioGroupField
                    clearable
                    name={meta.condicoesMoradia.isEnergiaEletricaDisponivel}
                    label='Possui energia elétrica?'
                  />
                </Cell>
                <Cell size={6}>
                  <SimNaoRadioGroupField
                    clearable
                    name={meta.animaisImovelFilter.possuiAnimais}
                    label='Possui animais?'
                  />
                </Cell>
                <Cell size={6}>
                  <TipoEnergiaEletricaSelectField
                    name={meta.condicoesMoradia.tipoOrigemEnergiaEletrica}
                    label='Tipo de energia elétrica'
                    disabled={hasEnergiaEletrica !== SimNaoEnum.SIM}
                  />
                </Cell>
                <Cell size={6}>
                  <AnimaisDomicilioSelectField
                    label='Tipos de animais no domicílio'
                    name={meta.animaisImovelFilter.animaisNoImovel}
                    disabled={hasAnimaisDomicilio !== SimNaoEnum.SIM}
                    multiple
                  />
                </Cell>
                <Cell size={6}>
                  <TipoAcessoDomicilioSelectField
                    label='Tipo de acesso'
                    name={meta.condicoesMoradia.tipoAcessoDomicilio}
                  />
                </Cell>
                <Cell size={6}>
                  <TipoDestinoLixoSelectField label='Destino do lixo' name={meta.condicoesMoradia.tipoDestinoLixo} />
                </Cell>
                <Cell size={6}>
                  <TipoEscoamentoSanitarioSelectField
                    label='Escoamento de banheiro'
                    name={meta.condicoesMoradia.tipoEscoamentoSanitario}
                  />
                </Cell>
                <Cell size={6} />
              </Grid>
            </Box>
          </Cell>

          <Cell size={12}>
            <HFlow justifyContent='flex-end'>
              <Button size='small' onClick={onModalClose}>
                Fechar
              </Button>
              <Button size='small' kind='normal' onClick={handleLimparCampos}>
                <HFlow>
                  <Icon icon='redo' />
                  Limpar filtros
                </HFlow>
              </Button>
              <SubmitButton size='small' handleSubmit={handleSubmit}>
                Filtrar
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </ModalBody>
    </Modal>
  )
}
