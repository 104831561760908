import { FieldStatusEnum } from 'components/form/final-form/components/FieldStatus/model-fieldStatus'
import { useEffect, useState } from 'react'
import { isEmpty } from 'util/validation/Util'

interface UseFieldStatusProps {
  initialValue: string
  currentValue: string
  dirty: boolean
  showModifiedStatus?: boolean
  onCheckIsEmpty?(value: string): boolean
}

export const useFieldStatus = (props: UseFieldStatusProps) => {
  const { initialValue, currentValue, dirty, showModifiedStatus = false, onCheckIsEmpty = isEmpty } = props

  const [status, setStatus] = useState<FieldStatusEnum>(FieldStatusEnum.NAO_MODIFICADO)

  useEffect(() => {
    if (!showModifiedStatus) return

    setStatus(getStatus(initialValue, currentValue, dirty, onCheckIsEmpty))
  }, [initialValue, dirty, currentValue, showModifiedStatus, onCheckIsEmpty])

  return {
    status,
    showChangeStatus: status !== FieldStatusEnum.NAO_MODIFICADO,
  }
}

const getStatus = (
  initialValue: string,
  currentValue: string,
  dirty: boolean,
  isEmptyFn: (value: string) => boolean
): FieldStatusEnum => {
  const initialHasContent = !isEmptyFn(initialValue)
  const currentHasContent = !isEmptyFn(currentValue)

  if (!initialHasContent && currentHasContent) {
    return FieldStatusEnum.ADICIONADO
  }

  if (initialHasContent && !currentHasContent) {
    return dirty ? FieldStatusEnum.REMOVIDO : FieldStatusEnum.NAO_MODIFICADO
  }

  if (initialHasContent && currentHasContent) {
    return dirty ? FieldStatusEnum.EDITADO : FieldStatusEnum.NAO_MODIFICADO
  }

  return FieldStatusEnum.NAO_MODIFICADO
}
