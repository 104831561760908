import { parseISO } from 'date-fns'
import { AvaliacaoProblemaInput, ProblemasECondicoesInput } from 'graphql/types.generated'
import { calculateAge } from 'util/date/calculateAge'
import { v4 as uuidv4 } from 'uuid'

import { EvolucaoProblema, Problema } from '../../../aside/types/ProblemaModel'
import { DataIdadeFieldModel } from '../../../components/DataIdadeField'
import { CiapsCidQueryModel } from '../../model'
import { ProblemaCondicaoModel } from './model-problemasCondicoes'
import { partitionEvolucoesProblemasFromQuery } from './utils/operations-problemasCondicoes'

export const convertProblemaCondicao = (
  input: Problema,
  dataNascimentoCidadao: LocalDate,
  value?: ProblemaCondicaoModel
): ProblemaCondicaoModel => {
  return {
    ...preencherAtributosProblemaCondicao(input, dataNascimentoCidadao, value),
    incluirNaListaProblemas: !!input,
    problemaCondicaoEvoluir: input,
  } as ProblemaCondicaoModel
}

export const convertDataProblema = (data: any, dataNascimentoCidadao: Date) => {
  const { years, months } = calculateAge(+dataNascimentoCidadao, data || Date.now())

  return {
    data: data,
    idade: data && {
      anos: years,
      meses: months,
    },
  } as DataIdadeFieldModel
}

const preencherAtributosProblemaCondicao = (
  input: Problema,
  dataNascimentoCidadao: LocalDate,
  value: ProblemaCondicaoModel
): Partial<ProblemaCondicaoModel> => {
  const dataInicio = value?.dataInicio?.data ?? input?.ultimaEvolucao?.dataInicio
  const dataFim = value?.dataFim?.data ?? input?.ultimaEvolucao?.dataFim

  return {
    editingId: value?.editingId,
    problemaId: input?.id,
    ciap: value?.ciap ?? input?.ciap,
    cid: value?.cid ?? input?.cid10,
    situacaoProblema: value?.situacaoProblema ?? input?.situacao,
    dataInicio: dataInicio && convertDataProblema(dataInicio, parseISO(dataNascimentoCidadao)),
    dataFim: dataFim && convertDataProblema(dataFim, parseISO(dataNascimentoCidadao)),
    observacao: value?.observacao ?? input?.ultimaEvolucao?.observacao,
  }
}

export const convertProblemaCondicaoFormToInput = (condicao: ProblemaCondicaoModel): AvaliacaoProblemaInput => ({
  ciapId: condicao.ciap?.id,
  cidId: condicao.cid?.id,
  incluirListaProblemas: condicao.incluirNaListaProblemas || condicao.isRegistradoAgora,
  situacao: condicao.situacaoProblema,
  dataInicio: condicao.dataInicio?.data,
  dataFim: condicao.dataFim?.data,
  observacao: condicao.observacao,
  id: condicao.problemaCondicaoEvoluir?.evolucaoAvaliacaoCiapCid?.id ?? condicao.editingId,
  problemaId: condicao.problemaId,
})

export const convertLPCProblemaCondicaoFormToInput = (form: ProblemaCondicaoModel): ProblemasECondicoesInput => ({
  cidId: form.cid?.id,
  ciapId: form.ciap?.id,
  situacao: form.situacaoProblema,
  dataFim: form.dataFim?.data,
  dataInicio: form.dataInicio?.data,
  observacao: form.observacao,
  automatico: form.isAutomatico ?? false,
})

export const convertCiapCidQueryToForm = (ciapCid: CiapsCidQueryModel): ProblemaCondicaoModel => {
  const { id, ciap, cid10, nota } = ciapCid

  return {
    _id: uuidv4(),
    editingId: id,
    ciap,
    cid: cid10,
    observacao: nota,
  }
}

export const convertEvolucaoProblemaQueryToForm = (
  evolucaoProblema: EvolucaoProblema,
  isAvaliacao: boolean,
  isNaListaDeProblemas?: boolean
): ProblemaCondicaoModel => {
  const {
    dataInicio,
    dataFim,
    observacao,
    situacao,
    problema: { id: problemaId, ciap, cid10, evolucaoAvaliacaoCiapCid },
  } = evolucaoProblema

  return {
    _id: uuidv4(),
    editingId: evolucaoAvaliacaoCiapCid?.id,
    situacaoProblema: situacao,
    observacao,
    ciap,
    cid: cid10,
    incluirNaListaProblemas: isNaListaDeProblemas,
    problemaId,
    problemaCondicaoEvoluir: evolucaoProblema.problema,
    dataInicio: { data: dataInicio },
    dataFim: { data: dataFim },
    isRegistradoAgora: !isAvaliacao,
    isAvaliadoAgora: isAvaliacao,
    isAntecedentes: false,
    isAutomatico: false,
  }
}

export const convertAvaliacaoProblemasECondicoesQueryToForm = (
  evolucoesProblema: EvolucaoProblema[],
  ciapsCids: CiapsCidQueryModel[]
): ProblemaCondicaoModel[] => {
  const { evolucoesProblemaAvaliacao } = partitionEvolucoesProblemasFromQuery(evolucoesProblema, ciapsCids)

  const apenasUltimasEvolucoes = evolucoesProblemaAvaliacao?.filter(
    (evolucao) => evolucao.id === evolucao.problema.ultimaEvolucao.id
  )

  const ciapsCidSemEvolucao = ciapsCids?.filter((item) => !item.isCiapCidNaListaDeProblemas)

  return [
    ...(apenasUltimasEvolucoes?.map((evolucaoProblema) => {
      const {
        problema: { ciap, cid10 },
      } = evolucaoProblema

      const { isCiapCidNaListaDeProblemas } = ciapsCids?.find(
        (ciapCid) => ciapCid.ciap?.id === ciap?.id && ciapCid?.cid10?.id === cid10?.id
      )

      return convertEvolucaoProblemaQueryToForm(evolucaoProblema, true, isCiapCidNaListaDeProblemas)
    }) ?? []),
    ...(ciapsCidSemEvolucao?.map(convertCiapCidQueryToForm) ?? []),
  ]
}
