import { blue, gray } from 'bold-ui/lib/styles/colors'
import { RangeArea, ReferenceArea, ValueRange } from 'components/chart'

import { BAIXO_PESO_COLORS, REFERENCE_AREAS_BAIXO_PESO } from './percentis/percentis-baixoPeso'
import { EUTROFIA_COLORS, REFERENCE_AREAS_EUTROFIA } from './percentis/percentis-eutrofia'
import { OBESIDADE_COLORS, REFERENCE_AREAS_OBESIDADE } from './percentis/percentis-obesidade'
import { REFERENCE_AREAS_SOBREPESO, SOBREPESO_COLORS } from './percentis/percentis-sobrepeso'

export const GANHO_PESO_EIXO_X: ValueRange = { init: 10, end: 40, step: 2 }
export const GANHO_PESO_EIXO_Y: ValueRange = { init: -10, end: 25, step: 1 }

const GANHO_PESO_RANGE_AREA_TERCEIRO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '3º trimestre',
  init: 27.05,
  end: 40,
  fillColor: 'none',
  tickColor: blue.c60,
}

const GANHO_PESO_RANGE_AREA_SEGUNDO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '2º trimestre',
  init: 13.05,
  end: 26.95,
  fillColor: 'none',
  tickColor: blue.c60,
}

const GANHO_PESO_RANGE_AREA_PRIMEIRO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '1º trimestre',
  init: 10,
  end: 12.95,
  fillColor: 'none',
  tickColor: blue.c60,
}

const GANHO_PESO_REFERENCE_LINE_PRIMEIRO_SEGUNDO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '',
  init: 13,
  end: null,
  fillColor: 'none',
  strokeColor: gray.c60,
}

const GANHO_PESO_REFERENCE_LINE_SEGUNDO_TERCEIRO_TRIMESTRE: Readonly<RangeArea<number>> = {
  name: '',
  init: 27,
  end: null,
  fillColor: 'none',
  strokeColor: gray.c60,
}

export const GANHO_PESO_RANGE_AREAS: RangeArea<number>[] = [
  GANHO_PESO_RANGE_AREA_PRIMEIRO_TRIMESTRE,
  GANHO_PESO_REFERENCE_LINE_PRIMEIRO_SEGUNDO_TRIMESTRE,
  GANHO_PESO_RANGE_AREA_SEGUNDO_TRIMESTRE,
  GANHO_PESO_REFERENCE_LINE_SEGUNDO_TERCEIRO_TRIMESTRE,
  GANHO_PESO_RANGE_AREA_TERCEIRO_TRIMESTRE,
]

export enum CategoriaImcReferenciaEnum {
  OBESIDADE = 'Obesidade',
  SOBREPESO = 'Sobrepeso',
  EUTROFIA = 'Eutrofia',
  BAIXO_PESO = 'Baixo peso',
}

export interface CategoriaImcColors {
  unexpectedSurface: string
  expectedSurface: string
  strokeAndTick: string
}

type CategoriaImcReferenciaInfo = {
  referenceAreas: Array<ReferenceArea<number>>
  colors: CategoriaImcColors
  imcMin?: number
  imcMax?: number
}

export const IMC_MAX_SOBREPESO_MIN_OBESIDADE = 30
export const IMC_MAX_EUTROFIA_MIN_SOBREPESO = 25
export const IMC_MAX_BAIXOPESO_MIN_EUTROFIA = 18.5

export const categoriaImcReferenciaRecord: Record<CategoriaImcReferenciaEnum, CategoriaImcReferenciaInfo> = {
  [CategoriaImcReferenciaEnum.OBESIDADE]: {
    referenceAreas: REFERENCE_AREAS_OBESIDADE,
    colors: OBESIDADE_COLORS,
    imcMin: IMC_MAX_SOBREPESO_MIN_OBESIDADE,
  },
  [CategoriaImcReferenciaEnum.SOBREPESO]: {
    referenceAreas: REFERENCE_AREAS_SOBREPESO,
    colors: SOBREPESO_COLORS,
    imcMin: IMC_MAX_EUTROFIA_MIN_SOBREPESO,
    imcMax: IMC_MAX_SOBREPESO_MIN_OBESIDADE,
  },
  [CategoriaImcReferenciaEnum.EUTROFIA]: {
    referenceAreas: REFERENCE_AREAS_EUTROFIA,
    colors: EUTROFIA_COLORS,
    imcMin: IMC_MAX_BAIXOPESO_MIN_EUTROFIA,
    imcMax: IMC_MAX_EUTROFIA_MIN_SOBREPESO,
  },
  [CategoriaImcReferenciaEnum.BAIXO_PESO]: {
    referenceAreas: REFERENCE_AREAS_BAIXO_PESO,
    colors: BAIXO_PESO_COLORS,
    imcMax: IMC_MAX_BAIXOPESO_MIN_EUTROFIA,
  },
}
