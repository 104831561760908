import { Alert, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading'
import { useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery } from 'graphql/hooks.generated'
import {
  Action,
  CuidadoCompartilhadoQueryFilterEnum,
  CuidadosCompartilhadoQueryInput,
  StatusCuidadoCompartilhadoEnum,
} from 'graphql/types.generated'
import React from 'react'

import {
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoSort,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoHeader } from './ListaCuidadoCompartilhadoHeader'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (
  profissionalId: ID,
  isUbs: boolean,
  value: ListaCuidadoCompartilhadoFilterModel
): CuidadosCompartilhadoQueryInput => {
  const hasSolicitante = value.profissionalSolicitante?.id
  return {
    query: value.query,
    cboId: value.cbo?.id,
    status: value.status,
    dataInicio: value.periodo?.startDate,
    dataFim: value.periodo?.endDate,
    solicitadosPelaMinhaEquipe: isUbs ? (hasSolicitante ? false : !value.switch) : false,
    solicitadosPorProfissionalId: isUbs ? hasSolicitante ?? (value.switch ? profissionalId : null) : null,
    executadosPelaMinhaEquipe: false,
    executadosPorProfissionalId: value.profissionalExecutante?.id,
    unidadeSaudeId: value.unidadeSaude?.id,
    somenteSemExecutante: false,
    somenteUnidadeExecutante: false,
    somenteUnidadeAcesso: !isUbs,
    somenteUnidadesBasicas: false,
    pageParams: { ...value.pageParams, sort: CuidadoCompartilhadoSort[value.pageParams.sort] },
  }
}

const resolveDiscutirAction = (actions: CuidadoCompartilhadoItemActions): Action => actions?.discutirCasoSolicitante

const FILTER_DEFAULT: ListaCuidadoCompartilhadoFilterModel = {
  status: [StatusCuidadoCompartilhadoEnum.AGUARDANDO, StatusCuidadoCompartilhadoEnum.RESPONDIDO],
  periodo: null,
  profissionalExecutante: null,
  profissionalSolicitante: null,
  unidadeSaude: null,
  equipe: [],
  cbo: null,
  switch: true,
}

export const ListaCuidadoCompartilhadoSolicitanteView = () => {
  const {
    acesso: { unidadeSaude },
    profissional,
  } = useAcessoLotacaoOrEstagio()

  const {
    data: { isEstabelecimentoAllowedAsReferenciaVinculacaoServico },
    loading: loadingVinculacaoServicoAllowed,
  } = useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery({
    variables: { tipoCoMs: unidadeSaude.tipo.codigoMs, subtipoCoMs: unidadeSaude.subtipo?.codigoMs },
    fetchPolicy: 'cache-first',
  })

  const isEstabelecimentoEspecializado =
    !loadingVinculacaoServicoAllowed && isEstabelecimentoAllowedAsReferenciaVinculacaoServico

  const filterToQuery = (value: ListaCuidadoCompartilhadoFilterModel) =>
    filterToQueryInput(profissional.id, !isEstabelecimentoEspecializado, value)

  if (loadingVinculacaoServicoAllowed) {
    return <PageLoading message='Carregando dados de cuidados compartilhados...' />
  }

  return (
    <>
      <ListaCuidadoCompartilhadoHeader />
      <PageContent fluid type='transparent'>
        <VFlow vSpacing={0.5}>
          <Alert type='info' inline>
            A lista apresenta todos os cuidados solicitados por você, além dos cuidados solicitados pela sua equipe.
          </Alert>
          <ListaCuidadoCompartilhadoView
            filterDefault={FILTER_DEFAULT}
            initialSort={CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE}
            itemsToSort={[
              CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_SOLICITANTE,
              CuidadoCompartilhadoSortEnum.STATUS_SOLICITANTE,
            ]}
            filterSwitchLabel='Ver somente as minhas solicitações'
            role={CuidadoCompartilhadoQueryFilterEnum.SOLICITANTE}
            filterToQueryInput={filterToQuery}
            resolveDiscutirAction={resolveDiscutirAction}
          />
        </VFlow>
      </PageContent>
    </>
  )
}
