import { Alert, Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { isEstagio } from 'components/auth/useSessionUtils'
import { useErrorHandler } from 'components/error'
import {
  DateField,
  DefaultFormFooter,
  Form,
  FormPrompt,
  FormRenderProps,
  NumberField,
  RichTextField,
} from 'components/form'
import { LotacaoEditableTable } from 'components/form/editable-table/LotacaoEditableTable'
import { RecordStringSelectField } from 'components/form/final-form/RecordStringSelectField/RecordStringSelectField'
import { ATIVIDADE_COLETIVA_REUNIOES_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { LotacaoResponsavelSection } from 'components/lotacao-responsavel/LotacaoResponsavelSection'
import { subMonths } from 'date-fns'
import { css } from 'emotion'
import { useSalvarAtividadeColetivaMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { useHistory } from 'react-router'
import { turnoRecord } from 'types/enums'
import { metaPath } from 'util/metaPath'

import { LocalAtividadeField } from '../componentes/LocalAtividadeField'
import { ObservacaoCidadaoEditableTable } from '../componentes/observacao-cidadao/ObservacaoCidadaoEditableTable'
import {
  AtividadeColetivaFormModel,
  LOTACAO_ESTAGIO_SEM_PERMISSAO,
  temaReuniaoRecord,
  tipoAtividadeRecord,
  TIPOS_ATIVIDADE_REUNIAO,
} from '../model-atividadeColetiva'
import { validateLotacoes } from '../validator-atividadeColetivaCommonForm'
import { convertAtividadeColetivaReuniaoFormModelToInput } from './convert-atividadeColetivaReuniao'
import { atividadeColetivaReuniaoFormValidator } from './validator-atividadeColetivaReuniaoForm'

const path = metaPath<AtividadeColetivaFormModel>()

interface AtividadeColetivaReuniaoFormProps {
  initialValues?: AtividadeColetivaFormModel
}

export default function AtividadeColetivaReuniaoForm(props: AtividadeColetivaReuniaoFormProps) {
  const { initialValues } = props
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()
  const history = useHistory()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const [salvarAtividadeColetiva] = useSalvarAtividadeColetivaMutation()

  const goBack = () => history.push(ATIVIDADE_COLETIVA_REUNIOES_PATH)

  const handleSubmit = (values: AtividadeColetivaFormModel) => {
    // TODO (Foundation): em #21794 será estudada uma melhor forma de apresentar esse erro
    const errorMessage = validateLotacoes(values)

    if (errorMessage) {
      alert('danger', errorMessage)
    } else {
      salvarAtividadeColetiva({
        variables: { input: convertAtividadeColetivaReuniaoFormModelToInput(values) },
      })
        .then((ret) => {
          goBack()
          alert('success', 'Atividade coletiva foi salva com sucesso.')
          return ret
        })
        .catch(handleRejection)
    }
  }

  const renderForm = (formProps: FormRenderProps<AtividadeColetivaFormModel>) => {
    const { values } = formProps
    const actionRespAtividadeColetiva = values.lotacaoResponsavel.lotacao.cbo.actions.atividadeColetiva

    return (
      <Grid gap={2}>
        <FormPrompt />
        <Cell size={12}>
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Responsabilidade de registro</Heading>
            <LotacaoResponsavelSection name={path.lotacaoResponsavel} isAtividadeColetiva />
            {!actionRespAtividadeColetiva.enabled && (
              <Alert type='danger' inline>
                {actionRespAtividadeColetiva.hint}
              </Alert>
            )}
            {isEstagio(values.lotacaoResponsavel.lotacao) && (
              <Alert type='danger' inline>
                {LOTACAO_ESTAGIO_SEM_PERMISSAO}
              </Alert>
            )}
          </VFlow>
        </Cell>

        <Cell xs={3} lg={2} style={styles.section}>
          <DateField
            name={path.dataAtividade}
            label='Data da atividade'
            title='Data da atividade'
            minDate={subMonths(serverTime, 12)}
            maxDate={serverTime}
            required
          />
        </Cell>

        <Cell xs={3} lg={2} style={styles.section}>
          <RecordStringSelectField name={path.turno} record={turnoRecord} label='Turno' title='Turno' required />
        </Cell>

        <Cell xs={3} lg={2} style={styles.section}>
          <NumberField
            name={path.numeroParticipantes}
            label='Nº de participantes'
            title='Nº de participantes'
            placeholder='Informe o número'
            min={1}
            max={999}
            maxLength={3}
            required
          />
        </Cell>

        <Cell size={8}>
          <LocalAtividadeField name={path.localAtividade} hideInep />
        </Cell>

        <Cell size={12} style={styles.section}>
          <Heading level={2} style={styles.title}>
            Profissionais envolvidos
          </Heading>
        </Cell>

        <Cell size={12}>
          {/* TODO Foundation #21877: adição de estagiários como profissionais envolvidos a ser implementada */
          /* TODO Foundation: avaliar filtragem de profissionais já adicionados e prof. responsável*/}
          <LotacaoEditableTable name={path.lotacoesEnvolvidas} />
        </Cell>

        <Cell size={12} style={styles.section}>
          <Heading level={2} style={styles.title}>
            Dados da atividade
          </Heading>
        </Cell>

        <Cell size={4}>
          <RecordStringSelectField
            name={path.tipoAtividade}
            record={tipoAtividadeRecord}
            subset={TIPOS_ATIVIDADE_REUNIAO}
            label='Tipo da atividade'
            title='Tipo da atividade'
            required
          />
        </Cell>

        <Cell size={4}>
          <RecordStringSelectField
            name={path.temasReuniao}
            record={temaReuniaoRecord}
            label='Temas da reunião'
            title='Temas da reunião'
            multiple
            required
          />
        </Cell>

        <Cell size={12}>
          <RichTextField
            name={path.anotacoes}
            label='Anotações da atividade'
            placeholder='Insira as anotações da atividade'
            maxLength={4000}
            height={6}
          />
        </Cell>

        <Cell size={12} style={styles.section}>
          <Heading level={2} style={styles.title}>
            Observações sobre cidadãos
          </Heading>
          <Text>
            Espaço para incluir estudos e observações referentes a um cidadão durante uma atividade de reunião de
            equipe.
          </Text>
        </Cell>

        <Cell size={12}>
          <ObservacaoCidadaoEditableTable
            name={path.observacoes}
            profissionalResponsavel={values.lotacaoResponsavel?.lotacao?.profissional}
            profissionaisEnvolvidos={values.lotacoesEnvolvidas?.map((item) => item.lotacao?.profissional)}
          />
        </Cell>

        <Cell alignSelf='flex-end' size={12}>
          <DefaultFormFooter handleSubmit={formProps.handleSubmit} onCancel={goBack} />
        </Cell>
      </Grid>
    )
  }

  return (
    <Form<AtividadeColetivaFormModel>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
      validate={atividadeColetivaReuniaoFormValidator(serverTime)}
    />
  )
}

const styles = {
  section: css`
    margin-top: 1.5rem;
  `,
  title: css`
    margin-bottom: 0.5rem;
  `,
}
