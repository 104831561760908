import { ExternalStyles, Theme, useStyles, VFlow } from 'bold-ui'
import React, { CSSProperties, ReactNode } from 'react'

import { UseFieldProps, usePecField } from '../../hooks/useField'
import { useFieldStatus } from '../../hooks/useFieldStatus'
import { FieldStatus } from './FieldStatus'

interface FieldStatusContainerProps extends Pick<UseFieldProps<any>, 'name'> {
  children: ReactNode
  showModifiedStatus?: boolean
  statusStyle?: ExternalStyles
}

export const FieldStatusContainer = (props: FieldStatusContainerProps) => {
  const { children, showModifiedStatus, statusStyle, name } = props

  const { input, meta, tools } = usePecField({ name })
  const { status, showChangeStatus } = useFieldStatus({
    initialValue: meta.initial,
    currentValue: input.value,
    dirty: meta.dirty,
    showModifiedStatus,
  })

  const { classes } = useStyles(createStyles, {
    showChangeStatus,
  })

  return (
    <VFlow style={classes.container} vSpacing={0.5}>
      {children}
      {showChangeStatus && (
        <VFlow style={statusStyle}>
          <FieldStatus status={status} handleReset={tools.resetToInitialValue} />
        </VFlow>
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme, { showChangeStatus }) => ({
  ...(showChangeStatus && {
    container: {
      border: `1px solid ${theme.pallete.gray.c80}`,
      borderRadius: '2px',
      padding: '1rem 1rem 0.5rem 1rem',
    } as CSSProperties,
  }),
})
