import { DataTable, TableFooter } from 'bold-ui'
import { sortByNomeProfissional } from 'components/form/editable-table/converter-lotacaoEditableTable'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { css } from 'emotion'
import { Cbo, Equipe, Profissional } from 'graphql/types.generated'
import React from 'react'

export interface LotacoesEnvolvidasTableModel {
  profissional: Pick<Profissional, 'id' | 'nome'>
  cbo: Pick<Cbo, 'id' | 'nome'>
  equipe?: Pick<Equipe, 'id' | 'nome'>
}

interface LotacoesEnvolvidasTableProps {
  items: LotacoesEnvolvidasTableModel[]
}

export function LotacoesEnvolvidasTable(props: LotacoesEnvolvidasTableProps) {
  const { items } = props
  const { paginatedItems, tableProps } = usePagination({ items: items.slice().sort(sortByNomeProfissional) })

  return (
    <TableBox>
      <DataTable<LotacoesEnvolvidasTableModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => row.profissional.nome,
            style: styles.tableRowNome,
          },
          {
            name: 'cbo',
            header: 'CBO',
            render: (row) => row.cbo.nome.capitalize(),
            style: styles.tableRowCBO,
          },
          {
            name: 'ine',
            header: 'INE',
            render: (row) => row.equipe?.nome ?? '-',
          },
        ]}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
  tableRowNome: css`
    width: 22rem;
  `,
  tableRowCBO: css`
    width: 20rem;
  `,
}
