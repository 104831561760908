import { blue, gray, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { format } from 'date-fns'
import {
  Cbo,
  DimensaoProcedimento,
  Equipe,
  EscutaInicialDetailQuery,
  FatoAtendimentoIndividual,
  FatoAtendimentoIndividualExames,
  FatoAtendimentoIndividualMedicamentos,
  FatoAtendimentoOdonto,
  FatoAtendimentoOdontoMedicamentos,
  FatoProcedAtend,
  HistoricoAtendimentoDomiciliarAdQuery,
  HistoricoAtendimentoOdontoPecQuery,
  HistoricoAvaliacaoElegibilidadeAdQuery,
  HistoricoConsultaPecQuery,
  HistoricoEscutaInicialQuery,
  HistoricoPreNatalPecQuery,
  HistoricoPuericulturaPecQuery,
  HistoricoPuerperioPecQuery,
  HistoricoVacinacaoPecQuery,
  Lotacao,
  MarcadorConsumoAlimentar,
  OrigemAtendimento,
  PerguntasQuestionarioEnum,
  Profissional,
  ReceitaMedicamento,
  ResultadoExame,
  TipoAtendimentoProfissional,
  TipoFrequencia,
  TipoGlicemia,
  TipoRegistroHistoricoClinico,
  UnidadeSaude,
} from 'graphql/types.generated'
import { parseInt } from 'lodash'
import { turnoByStringRecord, turnoRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { EvolucaoProblema } from '../../soap/aside/types/ProblemaModel'
import { tipoParticipacaoAtendimentoRecord } from '../../soap/finalizacao/model-finalizacao'
import { OpcoesMarcadoresConsumoAlimentarRecord } from '../../soap/objetivo/marcadores-consumo-alimentar/model'
import { resultadosExamesEspecificosFactory } from '../../soap/objetivo/resultados-exames/util-resultadosExames'
import { TipoDoseEnum } from '../../soap/plano/prescricao-medicamento/model-prescricao'
import {
  calculateDataFimTratamento,
  createPosologiaLabel,
} from '../../soap/plano/prescricao-medicamento/utils/utils-prescricao'
import {
  HistoricoAtendimentoModel,
  HistoricoResultadosExamesAvaliados,
  InformacoesAdministrativas,
  InformacoesAdministrativasProfissional,
  MEDICOES_ATENDIMENTO_PEC,
  MEDICOES_PRE_NATAL_PEC,
} from '../model/model-historico'

export const isHistoricoPrintEnable = (historico: HistoricoAtendimentoModel) => {
  const { hasObservacao, origemAtendimento, isAtendObsFinalizado } = historico

  const isFicha = isFichaAtendimento(origemAtendimento)

  if (hasObservacao && (!isAtendObsFinalizado || isFicha)) return false

  const isAtendimentoADAndNotElegibilidade =
    origemAtendimento === OrigemAtendimento.AD &&
    historico.tipoAtendProf !== TipoAtendimentoProfissional.AVALIACAO_ELEGIBILIDADE

  return (
    (origemAtendimento === OrigemAtendimento.PEC || isAtendimentoADAndNotElegibilidade) &&
    historico.tipoAtendProf !== TipoAtendimentoProfissional.ESCUTA_INICIAL &&
    historico.tipoApresentacao !== TipoRegistroHistoricoClinico.ESCUTA_INICIAL &&
    !historico.isCancelado
  )
}

export const hasSomeSubmodulesPlano = (form: HistoricoAtendimentoModel) =>
  form.hasAlergia ||
  form.hasPrescricaoMedicamento ||
  form.hasOrientacao ||
  form.hasAtestado ||
  form.hasEncaminhamento ||
  form.hasEncaminhamentoEspecializado ||
  form.hasSolicitacaoExame ||
  form.hasResultadoExame ||
  form.hasProcedimentoClinico ||
  form.hasMarcadorConsumoAlimentar ||
  form.hasCuidadoCompartilhado ||
  form.hasIvcf

/**
 * Check that all attributes of an object are null
 * except "__typename" and "id"
 * @param object
 * @returns a boolean
 */
export const hasAllAttrsNull = (object: any) => {
  return Object.keys(object)
    .filter((key) => key !== '__typename' && key !== 'id')
    .every((key) => !object[key])
}

export const hasMedicoes = (medicoes: HistoricoMedicaoModel[]) =>
  medicoes?.some((medicao) => MEDICOES_ATENDIMENTO_PEC.some((key) => !!medicao[key]))

export const getMedicoesPreNatal = (medicoes: HistoricoMedicaoModel[]) =>
  medicoes.filter((medicao) => MEDICOES_PRE_NATAL_PEC.some((key) => !!medicao[key]))

export const convertMedicoesToHistoricoMedicaoModel = (medicoes) =>
  medicoes?.map((medicao) => medicao as HistoricoMedicaoModel)

const hasExclusiveMedicoesFieldsFAI = (
  fato: FatoAtendimentoIndividual | FatoAtendimentoOdonto | FatoProcedAtend
): fato is FatoAtendimentoIndividual =>
  !!(fato as FatoAtendimentoIndividual).stVacinacaoEmDia || !!(fato as FatoAtendimentoIndividual).dum

export const convertFatoMedicoesToHistoricoMedicaoModel = (
  fato: FatoAtendimentoIndividual | FatoAtendimentoOdonto | FatoProcedAtend
): HistoricoMedicaoModel[] => {
  const medicoes: HistoricoMedicaoModel = {
    id: fato.id,
    // TODO (#21273) Analisar impacto e viabilidade de remover a obrigatoriedade da data de medicao no HistoricoMedicaoModel
    dataMedicao: '',
    valorPeso: fato.nuPeso,
    valorAltura: fato.nuAltura,
    valorPerimetroCefalico: fato.nuPerimetroCefalico,
    valorCircunferenciaAbdominal: fato.nuCircAbdominal,
    perimetroPanturrilha: fato.nuPerimPanturrilha,
    valorPressaoArterial:
      fato.nuPressaoSistolica && fato.nuPressaoDiastolica
        ? `${fato.nuPressaoSistolica}/${fato.nuPressaoDiastolica}`
        : null,
    valorFrequenciaRespiratoria: fato.nuFreqRespiratoria,
    valorFrequenciaCardiaca: fato.nuFreqCardiaca,
    valorTemperatura: fato.nuTemperatura,
    valorSaturacaoO2: fato.nuSaturacaoO2,
    valorImc: fato.nuImc,
    valorGlicemia: fato.nuGlicemia,
    tipoGlicemia: TipoGlicemia[fato.dimensaoTipoGlicemia],
  }

  if (hasExclusiveMedicoesFieldsFAI(fato)) {
    medicoes.valorVacinacaoEmDia = fato.stVacinacaoEmDia
    medicoes.dum = fato.dum
  }

  return [medicoes]
}

export const convertToEvolucaoProblema = (problemas) => problemas?.map((problema) => problema as EvolucaoProblema)

export const ClassificacaoRiscoColor = {
  PEC_ALTA: red.c40,
  PEC_INTERMEDIARIA: yellow.c60,
  PEC_BAIXA: green.c40,
  PEC_NAO_AGUDA: blue.c40,
  NAO_CLASSIFICADO: gray.c40,
}

export const horaOrTurnoByOrigem = (historicoAtendimento: HistoricoAtendimentoModel) =>
  historicoAtendimento.origemAtendimento === OrigemAtendimento.PEC ||
  (historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.CUIDADO_COMPARTILHADO &&
    isUndefinedOrNull(historicoAtendimento.idAtendProcessado))
    ? format(historicoAtendimento.dataAtendimento, 'HH:mm')
    : turnoRecord[historicoAtendimento.turno]

export const naoRegistradoNesseAtendimento = 'Não registrado nesse atendimento'

export const isTipoAtendimentoIndividual = (
  tipoAtendimento: TipoRegistroHistoricoClinico | TipoAtendimentoProfissional
) =>
  tipoAtendimento === TipoRegistroHistoricoClinico.CONSULTA ||
  tipoAtendimento === TipoRegistroHistoricoClinico.PRE_NATAL ||
  tipoAtendimento === TipoRegistroHistoricoClinico.PUERICULTURA ||
  tipoAtendimento === TipoRegistroHistoricoClinico.PUERPERIO

export const mountInfosAdministrativas = (data): InformacoesAdministrativas => {
  return {
    profissionalPrincipal: dimensoesToInformacoesAdministrativasProfissional(
      data.dimensaoProfissional,
      data.dimensaoCbo,
      data.dimensaoUnidadeSaude,
      data.dimensaoEquipe
    ),
    codigoInep: data.dimensaoInep?.nuIdentificador,
    nomeInep: data.dimensaoInep?.noEstabelecimento,
    localAtendimento: data.dimensaoLocalAtendimento?.descricao,
  }
}
export const mountInfosAdministrativasAtendimentoCompartilhado = (data): InformacoesAdministrativas => {
  return {
    profissionalPrincipal: dimensoesToInformacoesAdministrativasProfissional(
      data.dimensaoProfissional1,
      data.dimensaoCbo1,
      data.dimensaoUnidadeSaude1,
      data.dimensaoEquipe1
    ),
    profissionalCompartilhado: dimensoesToInformacoesAdministrativasProfissional(
      data.dimensaoProfissional2,
      data.dimensaoCbo2,
      data.dimensaoUnidadeSaude2,
      data.dimensaoEquipe2
    ),
    cpfCnsCuidador: data.cpfCuidador ?? data.cnsCuidador,
    tipoCuidador: data.dimensaoCuidador?.descricao,
    localAtendimento: data.dimensaoLocalAtendimento?.descricao,
    stAtendimentoCompartilhado: !!data.dimensaoProfissional2?.id,
    profissionalFinalizadorObservacao: dimensoesToInformacoesAdministrativasProfissional(
      data.dimProfFinalizadorObs,
      data.dimCboFinalizadorObs,
      data.dimUbsFinalizadorObs,
      data.dimEquipeFinalizadorObs
    ),
    tipoParticipacaoProfissionalConvidado: data.dimTipoParticipacaoProfConvidado?.descricao,
  }
}

export const idadeMarcoAlcancadoStringMount = (anos: number, meses: number) => {
  const anosText = 'ano'.pluralizeAndConcatValue(anos)
  const mesesText = `${meses} ${meses > 1 ? 'meses' : 'mês'}`
  const conectivoAnoMes = anos > 0 ? ' e ' : ''

  return ` (alcançado com ${anos > 0 ? anosText : ''}${meses > 0 ? `${conectivoAnoMes}${mesesText}` : ''})`
}

type HistoricoAtendimentoPec =
  | HistoricoConsultaPecQuery['historicoAtendimentoPec']
  | HistoricoAtendimentoDomiciliarAdQuery['historicoAtendimentoPec']
  | HistoricoPreNatalPecQuery['historicoAtendimentoPec']
  | HistoricoPuericulturaPecQuery['historicoAtendimentoPec']
  | HistoricoPuerperioPecQuery['historicoAtendimentoPec']
  | HistoricoAtendimentoOdontoPecQuery['historicoAtendimentoPec']
  | HistoricoAvaliacaoElegibilidadeAdQuery['historicoAtendimentoPec']
  | HistoricoEscutaInicialQuery['historicoAtendimentoPec']
  | HistoricoVacinacaoPecQuery['historicoVacinacaoPec']['atendimentoProfissional']
  | EscutaInicialDetailQuery['escutaInicialDetail']

export const mountInfosAdministrativasAtendimentosRecentes = (
  data: HistoricoAtendimentoPec,
  includeTipoPartProfConvidado?: boolean
): InformacoesAdministrativas => {
  const lotacaoFinalizador =
    data.atendimentoProfissionalObservacao?.atendimentoObservacao?.atendimentoProfissionalFinalizador?.lotacao
  return {
    profissionalPrincipal: lotacaoToInformacoesAdministrativasProfissional(data.lotacao),

    nomeCivilEstagiario: data.estagio?.profissional?.nomeCivil,
    nomeSocialEstagiario: data.estagio?.profissional?.nomeSocial,
    cboEstagiario: data.estagio?.cbo?.nome,
    nomeEquipeEstagiario: data.estagio?.equipe?.nome,
    ineEquipeEstagiario: data.estagio?.equipe?.ine,

    stAtendimentoCompartilhado: !!data.lotacaoAtendimentoCompartilhado?.profissional?.id,
    profissionalCompartilhado: lotacaoToInformacoesAdministrativasProfissional(data.lotacaoAtendimentoCompartilhado),

    localAtendimento: data.atendimento?.localAtendimento?.localAtendimentoExibicao,
    stRegistroTardio: data.atendimento?.isRegistroTardio,
    dataCriacaoRegistro: data.atendimento?.dataCriacaoRegistro,

    profissionalFinalizadorObservacao: lotacaoToInformacoesAdministrativasProfissional(lotacaoFinalizador),

    tipoParticipacaoProfissionalConvidado: includeTipoPartProfConvidado
      ? tipoParticipacaoAtendimentoRecord[data.tipoParticipacaoProfissionalConvidado]
      : null,
  }
}

const dimensoesToInformacoesAdministrativasProfissional = (
  dimensaoProfissional,
  dimensaoCbo,
  dimensaoUnidadeSaude,
  dimensaoEquipe
): InformacoesAdministrativasProfissional => {
  return {
    nome: dimensaoProfissional?.nome,
    cbo: dimensaoCbo?.nome,
    nomeUnidadeSaude: dimensaoUnidadeSaude?.nome,
    cnesUnidadeSaude: dimensaoUnidadeSaude?.cnes,
    nomeEquipe: dimensaoEquipe?.nome,
    ineEquipe: dimensaoEquipe?.ine,
  }
}

export const lotacaoToInformacoesAdministrativasProfissional = (
  lotacao: Partial<
    Omit<Lotacao, 'profissional' | 'cbo' | 'unidadeSaude' | 'equipe'> & {
      profissional: Partial<Profissional>
      cbo: Partial<Cbo>
      unidadeSaude: Partial<UnidadeSaude>
      equipe: Partial<Equipe>
    }
  >,
  cbo?: Partial<Cbo>,
  unidadeSaude?: Partial<UnidadeSaude>
): InformacoesAdministrativasProfissional => {
  return (
    (lotacao || cbo || unidadeSaude) && {
      nome: lotacao?.profissional?.nome,
      nomeCivil: lotacao?.profissional?.nomeCivil,
      nomeSocial: lotacao?.profissional?.nomeSocial,
      cbo: lotacao?.cbo?.nome ?? cbo?.nome,
      nomeUnidadeSaude: lotacao?.unidadeSaude?.nome ?? unidadeSaude?.nome,
      cnesUnidadeSaude: lotacao?.unidadeSaude?.cnes ?? unidadeSaude?.cnes,
      nomeEquipe: lotacao?.equipe?.nome,
      ineEquipe: lotacao?.equipe?.ine,
    }
  )
}

export const isFichaAtendimento = (origemAtendimento: OrigemAtendimento) =>
  origemAtendimento === OrigemAtendimento.CDS ||
  origemAtendimento === OrigemAtendimento.SISTEMA_TERCEIRO ||
  origemAtendimento === OrigemAtendimento.RAS_PEC

export const vacinadoExterior = (aplicadoExterior: boolean) =>
  isUndefinedOrNull(aplicadoExterior) ? '-' : aplicadoExterior ? 'Sim' : 'Não'

export const convertOpcaoMCAEnumToString = (marcadorConsumoAlimentar: MarcadorConsumoAlimentar) => {
  const respostasMap = new Map<PerguntasQuestionarioEnum, string>()

  marcadorConsumoAlimentar.respostas.forEach((resposta) => {
    const respostaMCA = resposta.opcoesSelecionadas
      ?.map((opcao) => OpcoesMarcadoresConsumoAlimentarRecord[opcao])
      .join(', ')

    respostasMap.set(resposta.pergunta, respostaMCA)
  })

  return respostasMap
}

export const convertFatoToReceitaMedicamento = (
  receita: FatoAtendimentoIndividualMedicamentos | FatoAtendimentoOdontoMedicamentos
): ReceitaMedicamento => {
  return {
    id: receita.id,
    medicamento: {
      id: receita.dimensaoCatmat.id,
      principioAtivo: receita.dimensaoCatmat.principioAtivo,
      concentracao: receita.dimensaoCatmat.concentracao,
      formaFarmaceutica: {
        id: receita.dimensaoCatmat.dimensaoFormaFarmaceutica.identificador,
        nome: receita.dimensaoCatmat.dimensaoFormaFarmaceutica.nome,
        ativo: receita.dimensaoCatmat.dimensaoFormaFarmaceutica.valido,
      },
    },
    dtInicioTratamento: receita.dataInicioTratamento,
    dtFimTratamento: calculateDataFimTratamento(
      receita.duracao,
      receita.dataInicioTratamento,
      receita.unidadeMedidaDuracao
    ),
    medidaTempoTratamento: receita.unidadeMedidaDuracao,
    quantidadeReceitada: receita.quantidade,
    posologia: createPosologiaLabel({
      tipoFrequencia: receita.tipoFrequencia,
      intervaloDose: receita.tipoFrequencia === TipoFrequencia.INTERVALO && parseInt(receita.frequenciaDose),
      frequenciaDose: receita.tipoFrequencia === TipoFrequencia.FREQUENCIA && parseInt(receita.frequenciaDose),
      quantidadePeriodoFrequencia: receita.doseFrequencia,
      unidadeMedidaTempoFrequencia: receita.unidadeFrequenciaDose,
      turno: turnoByStringRecord[receita.frequenciaDose],
      qtDose: null,
      unidadeMedida: null,
      qtDoseManha: receita.qtDoseManha,
      qtDoseTarde: receita.qtDoseTarde,
      qtDoseNoite: receita.qtDoseNoite,
      tipoDose: receita.doseUnica ? TipoDoseEnum.UNICA : null,
      doseDw: receita.dose,
    }),
    aplicacaoMedicamento: {
      id: receita.dimensaoViaAdministracao.id,
      nome: receita.dimensaoViaAdministracao.nome,
    },
  }
}

export const mergeResultadosExamesDw = (historico: FatoAtendimentoIndividual): ResultadoExame[] => {
  const idsResultadosEspecificos = new Set(
    historico.resultadosExamesEspecificos.map(
      (resultado: FatoAtendimentoIndividualExames) => resultado.dimensaoProcedimento.id
    )
  )

  const examesAvaliadosSemResultadoEspecifico = historico.examesAvaliados
    .filter((dimensao: DimensaoProcedimento) => {
      return !idsResultadosEspecificos.has(dimensao.id)
    })
    .map(
      (dimensao: DimensaoProcedimento) =>
        ({
          dimensaoProcedimento: { ...dimensao },
          resultadoEspecifico: null,
        } as HistoricoResultadosExamesAvaliados)
    )

  const resultadosEspecificos = historico.resultadosExamesEspecificos.map(
    (resultado: FatoAtendimentoIndividualExames) =>
      ({
        dimensaoProcedimento: { ...resultado.dimensaoProcedimento },
        resultadoEspecifico: { ...resultado },
      } as HistoricoResultadosExamesAvaliados)
  )

  const resultadosExames = examesAvaliadosSemResultadoEspecifico.concat(resultadosEspecificos)

  return resultadosExames.map(({ dimensaoProcedimento, resultadoEspecifico }: HistoricoResultadosExamesAvaliados) => ({
    id: dimensaoProcedimento.id,
    exame: {
      id: dimensaoProcedimento.id,
      descricao: dimensaoProcedimento.descricaoProcedimento,
      codigo: dimensaoProcedimento.codigoProcedimento,
      procedimentoReferencia: dimensaoProcedimento?.dimensaoProcedimentoReferencia && {
        id: dimensaoProcedimento.dimensaoProcedimentoReferencia.id,
        descricao: dimensaoProcedimento.dimensaoProcedimentoReferencia.descricaoProcedimento,
        codigo: dimensaoProcedimento.dimensaoProcedimentoReferencia.codigoProcedimento,
      },
    },
    dataSolicitacao: resultadoEspecifico?.dataSolicitacao,
    dataRealizacao: resultadoEspecifico?.dataRealizacao,
    dataResultado: resultadoEspecifico?.dataResultado,
    especifico: resultadoEspecifico && {
      id: resultadoEspecifico.id,
      igDias: resultadoEspecifico.nuResultadoDia,
      igSemanas: resultadoEspecifico.nuResultadoSemana,
      valor: !resultadoEspecifico.resultadoPuericultura && resultadoEspecifico.nuResultadoValor,
      dpp: resultadoEspecifico.dataResultadoData,
      exameEspecificoEnum:
        resultadosExamesEspecificosFactory[dimensaoProcedimento.codigoProcedimento]?.properties?.relatedDbEnum,
      resultadoExamePuericulturaEnum: resultadoEspecifico.resultadoPuericultura,
    },
  }))
}
