import { Alert, Button, Cell, Grid, HFlow } from 'bold-ui'
import {
  CheckboxField,
  CidadaoSelectField,
  CnsField,
  CpfField,
  DateField,
  DecimalField,
  NumberField,
  SexoSelectField,
  SimNaoRadioGroupField,
  SwitchField,
  TextField,
} from 'components/form'
import { css } from 'emotion'
import { FormApi } from 'final-form'
import React, { Fragment } from 'react'
import { EditableListForm, EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'
import { ProfissionalCpfCnsIdModel } from 'view/atividade-coletiva/model-atividadeColetiva'

import { PARTICIPANTE_EDITABLE_TABLE_PREFIX, participantesDecorator } from './calculator-participantesEditableTable'
import { ParticipanteEditableTableModel } from './ParticipanteEditableTable'
import { participanteEditableTableValidator } from './validator-participantesEditableTable'

interface ParticipanteEditableTableProps {
  now: Date
  required: boolean
  allItems: ParticipanteEditableTableModel[]
  profissionalResponsavel: ProfissionalCpfCnsIdModel
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
  isPraticaSaudePnct: boolean
  onSubmit: (formValues: ParticipanteEditableTableModel, formApi: FormApi) => Promise<number>
}

export const ParticipanteEditableTableForm = (props: ParticipanteEditableTableProps) => {
  const {
    now,
    required,
    allItems,
    profissionalResponsavel,
    profissionaisEnvolvidos,
    isPraticaSaudePnct,
    onSubmit,
  } = props

  const renderForm = (renderProps: EditableListFormRenderProps<ParticipanteEditableTableModel>) => {
    const { name, handleSubmit, values } = renderProps

    return (
      <Grid gap={0.5} style={styles.tableHeader}>
        <Cell lg={4} md={3}>
          <CidadaoSelectField
            name={name.cidadao}
            label='Cidadão'
            placeholder='Pesquise por nome, CPF ou CNS'
            required={required}
            disabled={values?.isCidadaoParticipanteFormOpen}
          />
        </Cell>

        <Cell size={2} style={styles.inputAvaliacaoAlterada}>
          <CheckboxField name={name.avaliacaoAlterada} label='Avaliação alterada' />
        </Cell>

        <Cell size={1}>
          <DecimalField name={name.peso} label='Peso (Kg)' min={0.5} max={500} maxLength={6} />
        </Cell>

        <Cell size={1}>
          <NumberField name={name.altura} label='Altura (cm)' min={20} max={250} maxLength={3} />
        </Cell>

        <Cell lg={4} md={5}>
          <HFlow hSpacing={1.5}>
            <SimNaoRadioGroupField
              name={name.pnctCessouHabitoFumar}
              label='Cessou o hábito de fumar?'
              disabled={!isPraticaSaudePnct}
            />
            <SimNaoRadioGroupField
              name={name.pnctAbandonouGrupo}
              label='Abandonou o grupo?'
              disabled={!isPraticaSaudePnct}
            />
          </HFlow>
        </Cell>

        <Cell size={12}>
          <SwitchField
            label='Adicionar cidadão sem cadastro na base local'
            initialValue={false}
            name={name.isCidadaoParticipanteFormOpen}
          />
        </Cell>

        {values?.isCidadaoParticipanteFormOpen && (
          <Fragment>
            <Cell size={3}>
              <TextField name={name.cidadaoParticipante.nome} label='Nome do cidadão' maxLength={70} uppercase />
            </Cell>

            <Cell size={2}>
              <CpfField label='CPF' name={name.cidadaoParticipante.cpf} />
            </Cell>

            <Cell size={2}>
              <CnsField label='CNS' name={name.cidadaoParticipante.cns} />
            </Cell>

            <Cell size={2}>
              <DateField
                name={name.cidadaoParticipante.dataNascimento}
                maxDate={now}
                label='Data de nascimento'
                required
              />
            </Cell>

            <Cell size={2}>
              <SexoSelectField label='Sexo' name={name.cidadaoParticipante.sexo} required />
            </Cell>

            <Cell size={7}>
              <Alert type='warning'>
                O registro é feito apenas na atividade coletiva, os campos não substituem o cadastro do cidadão.
              </Alert>
            </Cell>
          </Fragment>
        )}

        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button kind='primary' onClick={handleSubmit} size='small'>
              Adicionar
            </Button>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <EditableListForm<ParticipanteEditableTableModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={participanteEditableTableValidator(allItems, profissionalResponsavel, profissionaisEnvolvidos)}
      decorators={[participantesDecorator]}
      prefix={PARTICIPANTE_EDITABLE_TABLE_PREFIX}
    />
  )
}

const styles = {
  tableHeader: css`
    padding-block: 0.5rem;
  `,
  inputAvaliacaoAlterada: css`
    margin-top: 1.75rem;
  `,
}
