import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { parseISO } from 'date-fns'
import { SituacaoProblema } from 'graphql/types.generated'
import { partition, reject } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { v4 as uuidv4 } from 'uuid'
import { CiapCidPreNatal } from 'view/atendimentos/atendimento-individual/model'
import { CidPreNatalModel } from 'view/atendimentos/detail/soap/pre-natal/model-preNatal'

import { EvolucaoProblema, Problema } from '../../../../aside/types/ProblemaModel'
import { CiapsCidQueryModel } from '../../../model'
import { convertDataProblema, convertProblemaCondicao } from '../converter-problemasCondicoes'
import { ProblemaCondicaoModel } from '../model-problemasCondicoes'
import {
  isCiapW78,
  isEvolucao,
  isProblemaComCiapW78,
  isProblemaCondicaoComCiapW78Resolvido,
  isProblemaCondicaoDePreNatal,
  isProblemaCondicaoGravidezAltoRiscoAtivoOuLatente,
} from './verifications-problemasCondicoes'

export const findProblemaByCiapIdCidId = (problemasCidadao: Problema[], ciapId: ID, cid10Id: ID) =>
  (problemasCidadao ?? []).find(
    (problema) => problema && ciapId === problema.ciap?.id && cid10Id === problema.cid10?.id && !problema.descricaoOutro
  )

export const findProblemaById = (problemasCidadao: Problema[], id: ID) =>
  (problemasCidadao ?? []).find((problema) => problema.id === id)

export const createProblemaCondicaoAutomatico = (
  ciap: CiapSelectFieldModel,
  cid: CidSelectFieldModel,
  isMedico: boolean,
  situacao?: SituacaoProblema,
  incluirNaListaProblemas?: boolean,
  problemaJaExistente?: Problema,
  dataNascimentoCidadao?: string,
  dataInicio?: LocalDate,
  dataFim?: string,
  dataAtendimento?: Instant
): ProblemaCondicaoModel => {
  const dataInicioNaoConvertida = problemaJaExistente?.ultimaEvolucao.dataInicio ?? dataInicio ?? dataAtendimento

  return {
    _id: uuidv4(),
    problemaId: problemaJaExistente?.id,
    ciap: ciap,
    cid: problemaJaExistente?.cid10 || isMedico ? cid : null,
    situacaoProblema: situacao,
    isAutomatico: true,
    incluirNaListaProblemas: incluirNaListaProblemas,
    problemaCondicaoEvoluir: problemaJaExistente,
    dataInicio:
      dataNascimentoCidadao &&
      dataInicioNaoConvertida &&
      convertDataProblema(dateAsYyyyMmDd(toDate(dataInicioNaoConvertida)), parseISO(dataNascimentoCidadao)),
    dataFim: dataNascimentoCidadao && dataFim && convertDataProblema(dataFim, parseISO(dataNascimentoCidadao)),
  }
}

export const addProblemaCondicaoAutomatico = (
  listaProblemasCondicoes: ProblemaCondicaoModel[],
  novoProblemaCondicao: ProblemaCondicaoModel
) => {
  const { ciap: toAddCiap, cid: toAddCid, situacaoProblema: toAddSituacao } = novoProblemaCondicao
  const isW78 = isCiapW78(toAddCiap?.codigo)
  const jaPossuiProblemaCondicao = listaProblemasCondicoes.find(
    ({ ciap, cid, situacaoProblema }) =>
      toAddCiap?.codigo === ciap?.codigo &&
      toAddCid?.codigo === cid?.codigo &&
      (!isW78 || (toAddSituacao === situacaoProblema && toAddSituacao !== SituacaoProblema.RESOLVIDO))
  )
  if (!jaPossuiProblemaCondicao) return [...listaProblemasCondicoes, novoProblemaCondicao]
  return listaProblemasCondicoes
}

interface ProblemaCondicaoToRemove {
  ciapToRemove?: string
  cidToRemove?: string
  situacaoToRemove?: SituacaoProblema
  isAutomaticoToRemove?: boolean
}

export const removeProblemaCondicao = (
  listaProblemaCondicoes: ProblemaCondicaoModel[],
  isMedico: boolean,
  problemaCondicaoToRemove: ProblemaCondicaoToRemove
) => listaProblemaCondicoes.filter((problema) => !shouldRemoveProblema(problema, isMedico, problemaCondicaoToRemove))

function shouldRemoveProblema(
  problema: ProblemaCondicaoModel,
  isMedico: boolean,
  problemaCondicaoToRemove: ProblemaCondicaoToRemove
) {
  const { ciap, cid, situacaoProblema, isAutomatico } = problema
  const { ciapToRemove, cidToRemove, situacaoToRemove, isAutomaticoToRemove } = problemaCondicaoToRemove

  const isAutomaticoEqual = isUndefinedOrNull(isAutomaticoToRemove) || isAutomaticoToRemove === isAutomatico
  const isSituacaoEqual = isUndefinedOrNull(situacaoToRemove) || situacaoToRemove === situacaoProblema
  const isCiapOrCidEqual = isMedico
    ? ciapToRemove === ciap?.codigo || cidToRemove === cid?.codigo
    : ciapToRemove === ciap?.codigo
  return isAutomaticoEqual && isSituacaoEqual && isCiapOrCidEqual
}

export function removeProblemasCondicoesDePreNatal(
  listaProblemaCondicoes: ProblemaCondicaoModel[],
  hasProblemaComCiapW78AtivoPersistido: boolean
) {
  return reject(listaProblemaCondicoes, (problemaCondicao) =>
    isProblemaCondicaoDePreNatal(problemaCondicao, hasProblemaComCiapW78AtivoPersistido)
  )
}

export const atualizarProblemaCondicao = (
  novoProblemaCondicao: ProblemaCondicaoModel,
  problemaJaExistente: Problema,
  dataNascimentoCidadao: LocalDate
): ProblemaCondicaoModel => ({
  ...convertProblemaCondicao(problemaJaExistente, dataNascimentoCidadao, novoProblemaCondicao),
  _id: uuidv4(),
  incluirNaListaProblemas: true,
})

export const findProblemaComCiapW78 = (problemasCidadao?: Problema[]) =>
  problemasCidadao && problemasCidadao.find(isProblemaComCiapW78)

export const findProblemaComCiapW78Resolvido = (problemasCondicoes?: ProblemaCondicaoModel[]) =>
  problemasCondicoes && problemasCondicoes.find(isProblemaCondicaoComCiapW78Resolvido)

export const findProblemaCondicaoNaoEvolucaoGravidezAltoRisco = (problemasCondicoes?: ProblemaCondicaoModel[]) =>
  problemasCondicoes &&
  problemasCondicoes.find(
    (problemaCondicao: ProblemaCondicaoModel) =>
      isProblemaCondicaoGravidezAltoRiscoAtivoOuLatente(problemaCondicao) && !isEvolucao(problemaCondicao)
  )

export const createProblemaCondicaoAutomaticoPreNatal = (
  ciapCidPreNatal: CiapCidPreNatal,
  isMedico: boolean,
  situacaoProblema: SituacaoProblema,
  cid?: CidPreNatalModel,
  problemaPreNatalJaExistente?: Problema,
  dataNascimentoCidadao?: LocalDate,
  dataInicio?: LocalDate,
  dataFim?: LocalDate,
  dataAtendimento?: Instant
): ProblemaCondicaoModel =>
  createProblemaCondicaoAutomatico(
    ciapCidPreNatal.ciap,
    cid ?? ciapCidPreNatal.cid,
    isMedico,
    situacaoProblema,
    true,
    problemaPreNatalJaExistente,
    dataNascimentoCidadao,
    dataInicio,
    dataFim,
    dataAtendimento
  )

export const partitionEvolucoesProblemasFromQuery = (
  evolucoesProblemas: EvolucaoProblema[],
  ciapsCidsPelaAvaliacao: CiapsCidQueryModel[]
) => {
  const [evolucoesProblemaModal, evolucoesProblemaAvaliacao] = partition(evolucoesProblemas, (evolucaoProblema) => {
    const ciapId = evolucaoProblema.problema?.ciap?.id
    const cid10Id = evolucaoProblema.problema?.cid10?.id
    const isEvolucaoAdicionadoPelaAvaliacao = !isUndefinedOrNull(
      evolucaoProblema.problema?.evolucaoAvaliacaoCiapCid?.id
    )
    const resolvido = evolucaoProblema.situacao === SituacaoProblema.RESOLVIDO

    return !ciapsCidsPelaAvaliacao?.some(
      (ciapCidPelaAvaliacao) =>
        ciapCidPelaAvaliacao?.ciap?.id === ciapId &&
        ciapCidPelaAvaliacao?.cid10?.id === cid10Id &&
        (!resolvido || (resolvido && isEvolucaoAdicionadoPelaAvaliacao))
    )
  })

  return {
    evolucoesProblemaModal,
    evolucoesProblemaAvaliacao,
  }
}
